import React, { useMemo, useEffect, useCallback } from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import ActionUtil from '../../../utils/actionsUtil';
import useQueryParams from '../../../hooks/useQueryParams';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import StyledError from '../../Error/StyledError';
import Container from '../../Container/Container';
import {
  graphColorDefaults,
  getAssessmentPathDisplayName,
  ChartSliceTooltip,
  ChartPointSymbol,
  ChartTooltip,
  CustomLineLayer,
  generateTemplateCumulativeScores,
} from '../../Baseline/shared';

const TICK_VALUES = [0, 20, 40, 60, 80, 100];

function AssessmentResultsGraphWidget({ orgId, selectedContentDescription }) {
  const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

  const { memberId } = useQueryParams();

  const {
    cumulativeScores,
    isLoadingCumulativeScores,
    cumulativeScoresError,
    cumulativeUserScores,
    isLoadingCumulativeUserScores,
    cumulativeUserScoresError,
    cumulativeUserAttempts,
    isLoadingCumulativeUserAttempts,
    cumulativeUserAttemptsError,
    actions,
  } = useCybAssessmentPath();

  const currentPathCumulativeScores = useMemo(() => cumulativeScores?.[`${orgId}${contentDescriptionId}`], [cumulativeScores, contentDescriptionId, orgId]);

  const currentCumulativeUserScores = useMemo(
    () => cumulativeUserScores[`${orgId}${contentDescriptionId}${memberId}`],
    [cumulativeUserScores, contentDescriptionId, orgId, memberId]
  );

  const currentCumulativeUserAttempts = useMemo(
    () => cumulativeUserAttempts[`${orgId}${contentDescriptionId}${memberId}`],
    [cumulativeUserAttempts, contentDescriptionId, orgId, memberId]
  );

  const isLoading = useMemo(
    () => isLoadingCumulativeScores || isLoadingCumulativeUserScores || isLoadingCumulativeUserAttempts,
    [isLoadingCumulativeScores, isLoadingCumulativeUserScores, isLoadingCumulativeUserAttempts]
  );

  const error = useMemo(
    () => cumulativeScoresError ?? cumulativeUserScoresError ?? cumulativeUserAttemptsError,
    [cumulativeScoresError, cumulativeUserScoresError, cumulativeUserAttemptsError]
  );

  const parsedData = useMemo(() => {
    if (memberId) {
      if (currentCumulativeUserAttempts?.default && currentCumulativeUserScores?.default) {
        const lowAttempts = Object.entries(currentCumulativeUserAttempts?.default)
          .filter(([key]) => key.includes('_Low_Attempts'))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        return Object.entries({
          ...currentCumulativeUserScores?.default,
          ...lowAttempts,
        });
      }

      // placeholder stub of cumulativeUserScores & cumulativeUserAttempts in expected schema
      return generateTemplateCumulativeScores(selectedContentDescription, true);
    }

    if (currentPathCumulativeScores?.default) {
      return Object.entries(currentPathCumulativeScores?.default);
    }

    // placeholder stub of currentPathCumulativeScores in expected schema
    return generateTemplateCumulativeScores(selectedContentDescription);
  }, [memberId, selectedContentDescription, currentPathCumulativeScores?.default, currentCumulativeUserAttempts?.default, currentCumulativeUserScores?.default]);

  const getScatteredPointColor = useCallback(
    (lowAttemptDisplayName) => {
      // Cybersecurity Fundamentals_Low_Attempts > Cybersecurity Fundamentals
      const canonicalPathDisplayName = lowAttemptDisplayName.split('_Low_Attempts')[0];

      let graphColor = '';

      parsedData.forEach(([key], index) => {
        if (canonicalPathDisplayName === key) {
          graphColor = graphColorDefaults.d3SchemaCategory10[index];
        }
      });

      return graphColor;
    },
    [parsedData]
  );

  const chartData = parsedData?.map(([key, value]) => {
    const displayName = getAssessmentPathDisplayName(key);

    let mappedData = [];

    if (memberId && !key.includes('_Low_Attempts')) {
      const consolidatedData = {};

      value.forEach((entry, index) => {
        if (index === value.length - 1) {
          consolidatedData.endPoint = {
            ...entry,
            marker: true,
            completed_at: moment(entry.completed_at).add(1, 'months').startOf('month').format('YYYY-MM-DD'),
          };
        } else if (entry.score !== null) {
          if (!(entry.score in consolidatedData) || entry.completed_at < consolidatedData[entry.score].completed_at) {
            consolidatedData[entry.score] = entry;
          }
        } else if (index === 0) {
          consolidatedData.starterPoint = {
            ...entry,
            marker: true,
          };
        }
      });

      const result = Object.values(consolidatedData);

      mappedData = result.map((entry, index) => {
        const score = Math.round(parseFloat(entry.score));
        let y = Number.isNaN(score) ? 0 : Number(score);

        if (index === 0 && entry.marker) {
          const nextScore = Math.round(parseFloat(result[index + 1]?.score ?? 0));
          y = Number(nextScore);
        }

        return {
          x: moment(entry.completed_at).format('YYYY-MM-DD'),
          y,
          marker: entry.marker,
        };
      });
    } else {
      mappedData = value.map((entry) => {
        const score = Math.round(parseFloat(entry.score));
        let y = 0;

        if (Number.isInteger(score)) {
          y = Number(score);
        } else if (entry.stub) {
          y = null;
        }

        const response = {
          x: moment(entry.completed_at ?? entry.period).format('YYYY-MM-DD'),
          y,
        };

        if (memberId) {
          if (displayName.includes('_Low_Attempts')) {
            response.scattered = true;
            response.scatteredPointColor = getScatteredPointColor(displayName);
          }
        }

        return response;
      });
    }

    return {
      id: displayName,
      data: mappedData,
    };
  });

  const xScaleAndAxis = useMemo(() => {
    let maxDataPointsCount = 0;

    chartData?.forEach((struct) => {
      if (struct.data.length > maxDataPointsCount) {
        maxDataPointsCount = struct.data.length;
      }
    });

    const xScale = {
      format: '%Y-%m-%d',
      precision: memberId ? 'day' : 'month',
      type: 'time',
      useUTC: false,
    };

    const axisBottom = {
      tickSize: 10,
      tickPadding: 10,
      tickRotation: 0,
      format: '%b %Y',
    };

    if (!memberId) {
      axisBottom.tickValues = 'every month';
      axisBottom.format = (value) => moment(value).format("MMM 'YY");
    }

    if (memberId && maxDataPointsCount >= 4) {
      axisBottom.tickValues = 'every month';
    }

    return {
      xScale,
      axisBottom,
    };
  }, [chartData, memberId]);

  const gridXValues = useMemo(() => {
    if (memberId) {
      return undefined;
    }
    return chartData?.[0]?.data?.map((struct) => moment(struct.x).toDate());
  }, [memberId, chartData]);

  const layers = useMemo(() => {
    if (memberId) {
      return ['grid', 'markers', 'areas', CustomLineLayer, 'points', 'axes', 'mesh', 'slices', 'crosshair'];
    }

    return undefined;
  }, [memberId]);

  useEffect(() => {
    if (orgId && contentDescriptionId) {
      if (!currentPathCumulativeScores) {
        actions.getCumulativeScores(orgId, contentDescriptionId);
      }
    }
  }, [orgId, contentDescriptionId, currentPathCumulativeScores]);

  useEffect(() => {
    if (orgId && contentDescriptionId && memberId) {
      if (!currentCumulativeUserScores) {
        actions.getCumulativeUserScores(orgId, contentDescriptionId, memberId);
      }
      if (!currentCumulativeUserAttempts) {
        actions.getCumulativeUserAttempts(orgId, contentDescriptionId, memberId);
      }

      ActionUtil.scrollToTop();
    }
  }, [orgId, contentDescriptionId, memberId]);

  return (
    <WidgetContainer className="pb-0" omitBackground>
      <Header as="h3" className="mb-3">
        Trend
      </Header>
      <div className="relative">
        <div className="relative w-full h-[300px]">
          <ResponsiveLine
            data={chartData}
            margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
            curve="monotoneX"
            xFormat="time:%Y-%m-%d"
            xScale={xScaleAndAxis.xScale}
            yFormat=" >-.2f"
            yScale={{
              type: 'linear',
              min: TICK_VALUES[0],
              max: TICK_VALUES[TICK_VALUES.length - 1],
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: TICK_VALUES,
              format: (value) => `${value}%`,
            }}
            axisBottom={xScaleAndAxis.axisBottom}
            lineWidth={2}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointSymbol={ChartPointSymbol}
            tooltip={ChartTooltip}
            sliceTooltip={ChartSliceTooltip}
            layers={layers}
            enableSlices={memberId ? false : 'x'}
            gridXValues={gridXValues}
            gridYValues={TICK_VALUES}
            crosshairType="cross"
            useMesh
          />
          <If condition={isLoading || Boolean(error)}>
            <div className="flex absolute top-0 left-0 flex-col justify-center items-center w-full h-full bg-white/90">
              <Container>
                <If condition={isLoading}>
                  <Loading message="Loading..." />
                </If>
                <If condition={Boolean(error)}>
                  <StyledError error={error} />
                </If>
              </Container>
            </div>
          </If>
        </div>
        <If condition={chartData?.length > 0}>
          <div className="flex flex-row flex-wrap items-start ml-9">
            {chartData?.map((entry, index) => (
              <If condition={!entry.id.includes('_Low_Attempts')} key={entry.id + index.toString()}>
                <div className="flex flex-row items-center py-1 px-2 mt-2 mr-3 hover:bg-black/[0.03] rounded-sm cursor-default">
                  <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: graphColorDefaults.d3SchemaCategory10[index] }} />
                  <span className="text-[11px] font-semibold text-gray-600 whitespace-nowrap">{entry.id}</span>
                </div>
              </If>
            ))}
          </div>
        </If>
      </div>
    </WidgetContainer>
  );
}

export default AssessmentResultsGraphWidget;
