import React, { useState, useMemo, useEffect, useCallback } from 'react';
import AdminTable from '../../AdminTable/AdminTable';
import { formatTableColumns } from '../../Baseline/shared';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import Loading from '../../Loading/Loading';
import AddLink from '../../AddLink/AddLink';
import If from '../../If/If';
import Icon from '../../Icon/Icon';
import BugsnagUtil from '../../../utils/bugsnagUtil';
import Agents from '../../../agents/agents';

const COLUMN_HEADING_MAPPINGS = {
  assessment_name: 'Topic',
};

const renderColumn = (row, _fullRow, _headings, column) => {
  if (row.value !== undefined && column.showCol) {
    const percentage = Math.round(parseFloat(row.value));

    if (row.value === null) {
      return <i>incomplete</i>;
    }

    if (column.key === 'difference') {
      const sign = percentage < 0 ? '-' : '+';
      const displayValue = Math.abs(percentage);

      if (percentage > 0) {
        return (
          <div className="flex flex-row items-center ">
            <Icon name="trending-up" className="shrink-0 mt-1 w-5 h-5 fill-green-600" />
            <span className="ml-2 text-green-600">{`${sign}${displayValue}%`}</span>
          </div>
        );
      }

      if (percentage < 0) {
        return (
          <div className="flex flex-row items-center">
            <Icon name="trending-down" className="shrink-0 mt-1 w-5 h-5 fill-red-500" />
            <span className="ml-2 text-red-600">{`${sign}${displayValue}%`}</span>
          </div>
        );
      }

      return `${displayValue}%`;
    }

    return `${percentage}%`;
  }

  return '';
};

function TableWidget({ orgId, selectedContentDescription, baseQuery }) {
  const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

  const [isLoadingAssessmentProgress, setIsLoadingAssessmentProgress] = useState(false);
  const [assessmentProgressError, setAssessmentProgressError] = useState(null);
  const [assessmentProgress, setAssessmentProgress] = useState(null);

  const updatedColumnHeadings = useMemo(
    () =>
      assessmentProgress?.columns?.map((col) => ({
        ...col,
        display: COLUMN_HEADING_MAPPINGS[col.key] ?? col.display,
      })),
    [assessmentProgress]
  );

  const formatColumns = useMemo(() => {
    const targetKeys = ['average_score', 'first_score', 'best_score', 'difference'];

    return formatTableColumns(assessmentProgress?.columns, targetKeys, renderColumn);
  }, [renderColumn, assessmentProgress]);

  const getAssessmentProgress = useCallback(async (teamId, contentId, baseQueryParams) => {
    if (!teamId || !contentId) {
      return;
    }

    try {
      setIsLoadingAssessmentProgress(true);
      setAssessmentProgressError(null);

      const response = await Agents.reports.getReportData(teamId, 'assessment-path-progress', `?contentDescriptionId=${contentId}&format=table&${baseQueryParams}`);

      setAssessmentProgress(response);
    } catch (getAssessmentProgressError) {
      setAssessmentProgressError(getAssessmentProgressError);
      BugsnagUtil.notify(getAssessmentProgressError);
    } finally {
      setIsLoadingAssessmentProgress(false);
    }
  }, []);

  const toViewLink = useMemo(() => {
    const { pathname } = window.location;
    return pathname.replace('organization/dashboard', 'reporting/report/content-type/?contentTypeIds[]=46&contentType=Assessment');
  }, [orgId]);

  useEffect(() => {
    if (orgId && contentDescriptionId) {
      getAssessmentProgress(orgId, contentDescriptionId, baseQuery);
    }
  }, [orgId, contentDescriptionId, baseQuery]);

  return (
    <WidgetContainer className="flex flex-col pt-0" omitBorder omitPadding omitBackground>
      <div className="flex flex-row items-center mb-6">
        <Header as="h4" className="mb-0 font-semibold">
          Detail
        </Header>
        <If condition={isLoadingAssessmentProgress}>
          <Loading wrapperClassName="py-0 ml-3" className="w-4 h-4 border-2" />
        </If>
      </div>
      <AdminTable
        wrapperClassName="m-0 w-full"
        colClasses="whitespace-nowrap"
        headings={updatedColumnHeadings}
        data={assessmentProgress?.tableData}
        formatColumns={formatColumns}
        tableError={assessmentProgressError}
      />
      <div className="pt-6 text-end">
        <AddLink className="inline-block text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer" to={toViewLink}>
          View all
        </AddLink>
      </div>
    </WidgetContainer>
  );
}

export default TableWidget;
