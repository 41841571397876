import React, { useEffect, useState } from 'react';
import LoadSpinner from '../../../Clab/components/Loading/LoadSpinner';
import { useCybAssessment } from '../../../../providers/CybAssessmentProvider';
import { useClab } from '../../../../providers/ClabProvider';

function IntroPhase({ start, canStart }) {
  const btnBg = canStart ? 'cursor-pointer bg-cyb-pink-500 hover:bg-cyb-pink-600' : 'cursor-not-allowed bg-gray-500 hover:bg-gray-600';
  const btnClasses = `rounded-md font-semibold py-3 px-16 text-white text-center text-base ${btnBg}`;

  const startIfAllowed = () => {
    if (!canStart) {
      return;
    }
    start();
  };

  return (
    <div className="flex flex-col items-center py-10 px-8">
      <button type="button" onClick={startIfAllowed} className={btnClasses}>
        Start Assessment
      </button>
    </div>
  );
}

function ChallengeAssessmentStart({ id, isCaseStudy = false }) {
  const [canStart, setCanStart] = useState(false);
  const { resources } = useClab();
  const { loading, starting, launched, startAssessment, loadAssessment, assessment } = useCybAssessment();

  const init = () => {
    loadAssessment(id);
  };

  const start = async () => {
    await startAssessment(id);
  };

  useEffect(() => {
    // Only init if this is a new assessment
    if (assessment?.uuid === id) {
      return;
    }
    init();
  }, [id]);

  useEffect(() => {
    // Check to see if we have at least one resource set to 'running', if so, we are allowed to start this lab
    if (canStart || !resources?.length) {
      return;
    }
    const hasRunningResource = () => {
      for (let i = 0; i < resources.length; i++) {
        if (resources[i].status === 'running') {
          return true;
        }
      }
      return false;
    };
    setCanStart(hasRunningResource());
  }, [resources]);

  useEffect(() => {
    // We can always start case studies, since we aren't waiting for labs to load.
    if (isCaseStudy) {
      setCanStart(true);
    }
  }, [isCaseStudy]);

  if (loading || starting) {
    const text = starting ? 'Starting...' : 'Loading...';
    return (
      <div className="flex items-center w-full">
        <LoadSpinner text={text} spinnerClass="h-14 w-14 text-center" />
      </div>
    );
  }

  if (!launched) {
    return <IntroPhase start={start} canStart={canStart} />;
  }

  return null;
}

export default ChallengeAssessmentStart;
