import React from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import CurriculumItems from '../../components/Assignments/CurriculumItems';
import Agents from '../../agents/agents';
import EnrollmentTransformer from '../../transformers/enrollmentTransformer';

const GoalPath = inject(
  'authStore',
  'enrollmentStore'
)(
  observer(
    class GoalPath extends React.Component {
      state = {
        loading: true,
        enrollmentItem: null,
        enrollmentId: null,
        contentId: null,
        content: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        const enrollmentIdParam = this.props.match.params.id;
        // Get the active enrollment (if there is one)
        const enrollmentId = enrollmentIdParam === 'unstarted' ? null : 1 * this.props.match.params.id; // ensure we are casting as an int!
        const contentId = 1 * this.props.match.params.contentId; // ensure we are casting as an int!

        // Determine method for getting goal details based on whether we have enrollment ID or not
        const agentMethod = enrollmentId ? 'getEnrollmentDetails' : 'getChildProgress';
        const agentArg = enrollmentId || contentId || null;
        // Get content details
        Agents.catalog
          .getContentDescriptionById(contentId)
          .then((content) => {
            Agents.enrollments[agentMethod](agentArg, '?includeEmpty=1')
              .then((response) => {
                if (!!response && response.length) {
                  const newState = {
                    ...this.state,
                    enrollmentId,
                    contentId,
                    content,
                    loading: false,
                    enrollmentItem: response,
                  };
                  this.setState(newState);
                } else {
                  const newState = {
                    ...this.state,
                    loading: false,
                  };
                  this.setState(newState);
                }
              })
              .catch((error) => {
                Bugsnag.notify(error);
              });
          })
          .catch((error) => {
            Bugsnag.notify(error);
          });
      }

      launchAssessmentContent = (enrollment, contentId, baseUrl) => {
        if (!enrollment.buckets?.length) {
          return this.props.navigate(`${baseUrl}/item/enrollment/activity/${contentId}`);
        }

        const activeItem = enrollment.buckets[0].items[0];
        const pathId = activeItem?.content_description_id;

        return this.props.enrollmentStore
          .startEnrollment(contentId, { exclude_progress_before: enrollment.exclude_progress_before })
          .then((responseInfo) => {
            const { enrollmentId } = responseInfo;
            const isActivity = !!responseInfo && responseInfo.enrollment && responseInfo.enrollment.is_activity;

            return this.props.navigate(`/enrollments-launcher?launch=${enrollmentId}${isActivity ? '&isActivity=true' : ''}`);
          })
          .catch(() => {
            // Handle Failure With Old Logic
            if (Boolean(pathId) && pathId !== contentId) {
              return this.props.navigate(`${baseUrl}/item/${contentId}`);
            }

            return this.props.navigate(baseUrl);
          });
      };

      launchContent = (enrollment, contentId) => {
        const baseUrl = `/immersive/${enrollment.id}`;

        if (enrollment.type === 'Assessment Path' || enrollment.type === 'Assessment') {
          return this.launchAssessmentContent(enrollment, contentId, baseUrl);
        }
        const curriculumItem = this.props.enrollmentStore.findCurriculumItemByContentId(enrollment, contentId);

        const { learning_modules: itemModules, sessions } = curriculumItem;
        let itemId = curriculumItem?.content_description_id || null;
        if (!itemId && curriculumItem?.content_description && curriculumItem?.content_description.id) {
          itemId = curriculumItem?.content_description.id;
        }
        if (!itemModules && !sessions && itemId) {
          // A curriculum item with an enrollment activity inside of it
          return this.props.navigate(`${baseUrl}/item/enrollment/activity/${itemId}`);
        }
        if (itemId) {
          return this.props.navigate(`${baseUrl}/item/${itemId}`);
        }
        return this.props.navigate(baseUrl);
      };

      handleCurriculumItemLaunch = (item) => {
        const { enrollmentId, contentId, content } = this.state;
        const childContentId = item.content_description_id;
        const parentType = content.content_type?.nice_name ?? 'Curriculum';
        if (!enrollmentId) {
          this.props.enrollmentStore
            .startEnrollment(contentId, parentType === 'Assessment Path' ? { exclude_progress_before: 'now' } : null, 'Curriculum')
            .then((response) => {
              const enrollment = EnrollmentTransformer.transformEnrollmentData([response.enrollment])[0];

              this.launchContent(enrollment, childContentId);
            })
            .catch((error) => {
              Bugsnag.notify(error);
            });
        } else {
          this.props.enrollmentStore
            .getEnrollmentById(enrollmentId)
            .then((response) => {
              const enrollment = EnrollmentTransformer.transformEnrollmentData([response])[0];
              this.launchContent(enrollment, childContentId);
            })
            .catch((error) => {
              Bugsnag.notify(error);
            });
        }
      };

      render() {
        const { loading, content, enrollmentItem } = this.state;
        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (enrollmentItem) {
          return (
            <Container size="md">
              <Title title={content.title} />
              <CurriculumItems collection={enrollmentItem} handleCurriculumItemLaunch={this.handleCurriculumItemLaunch} />
            </Container>
          );
        }
        // If done loading and no content to show, redirect user back to goals (likely an error)
        return <Navigate to="/goals" />;
      }
    }
  )
);

export default withRouter(GoalPath);
