import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import StyledError from '../../Error/StyledError';
import Container from '../../Container/Container';
import { graphColorDefaults, getAssessmentPathDisplayName, ChartSliceTooltip, ChartPointSymbol, ChartTooltip, generateTemplateCumulativeScores } from '../../Baseline/shared';
import Agents from '../../../agents/agents';
import BugsnagUtil from '../../../utils/bugsnagUtil';

const TICK_VALUES = [0, 20, 40, 60, 80, 100];

function GraphWidget({ orgId, selectedContentDescription, selectedScoreType, baseQuery }) {
  const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

  const [isLoadingAssessmentProgress, setIsLoadingAssessmentProgress] = useState(false);
  const [assessmentProgressError, setAssessmentProgressError] = useState(null);
  const [assessmentProgress, setAssessmentProgress] = useState(null);

  const parsedData = useMemo(() => {
    if (assessmentProgress) {
      return Object.entries(assessmentProgress);
    }

    // placeholder stub of assessmentProgress in expected schema
    return generateTemplateCumulativeScores(selectedContentDescription);
  }, [selectedContentDescription, assessmentProgress]);

  const chartData = parsedData?.map(([key, value]) => {
    const displayName = getAssessmentPathDisplayName(key);

    let mappedData = [];

    mappedData = value.map((entry) => {
      const score = Math.round(parseFloat(entry.score));
      let y = 0;

      if (Number.isInteger(score)) {
        y = Number(score);
      } else if (entry.stub) {
        y = null;
      }

      return {
        x: moment(entry.completed_at ?? entry.period).format('YYYY-MM-DD'),
        y,
      };
    });

    return {
      id: displayName,
      data: mappedData,
    };
  });

  const xScaleAndAxis = useMemo(() => {
    let maxDataPointsCount = 0;

    chartData?.forEach((struct) => {
      if (struct.data.length > maxDataPointsCount) {
        maxDataPointsCount = struct.data.length;
      }
    });

    const xScale = {
      format: '%Y-%m-%d',
      precision: 'month',
      type: 'time',
      useUTC: false,
    };

    const axisBottom = {
      tickSize: 10,
      tickPadding: 10,
      tickRotation: 0,
      tickValues: 'every month',
      format: (value) => moment(value).format("MMM 'YY"),
    };

    return {
      xScale,
      axisBottom,
    };
  }, [chartData]);

  const gridXValues = useMemo(() => chartData?.[0]?.data?.map((struct) => moment(struct.x).toDate()), [chartData]);

  const getAssessmentProgress = useCallback(async (teamId, contentId, scoreType, baseQueryParams) => {
    if (!teamId || !contentId) {
      return;
    }

    try {
      setIsLoadingAssessmentProgress(true);
      setAssessmentProgressError(null);

      const response = await Agents.reports.getReportData(
        teamId,
        'assessment-path-progress-per-interval',
        `?contentDescriptionId=${contentId}&scoreType=${scoreType.value}&interval=month&${baseQueryParams}`
      );

      setAssessmentProgress(response);
    } catch (getAssessmentProgressError) {
      setAssessmentProgressError(getAssessmentProgressError);
      BugsnagUtil.notify(getAssessmentProgressError);
    } finally {
      setIsLoadingAssessmentProgress(false);
    }
  }, []);

  useEffect(() => {
    if (orgId && contentDescriptionId) {
      getAssessmentProgress(orgId, contentDescriptionId, selectedScoreType, baseQuery);
    }
  }, [orgId, contentDescriptionId, selectedScoreType, baseQuery]);

  return (
    <WidgetContainer className="pb-0" omitBackground omitPadding omitBorder>
      <Header as="h4" className="mb-3 font-semibold">
        Trend
      </Header>
      <div className="relative">
        <div className="relative w-full h-[300px]">
          <ResponsiveLine
            data={chartData}
            margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
            curve="monotoneX"
            xFormat="time:%Y-%m-%d"
            xScale={xScaleAndAxis.xScale}
            yFormat=" >-.2f"
            yScale={{
              type: 'linear',
              min: TICK_VALUES[0],
              max: TICK_VALUES[TICK_VALUES.length - 1],
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: TICK_VALUES,
              format: (value) => `${value}%`,
            }}
            axisBottom={xScaleAndAxis.axisBottom}
            lineWidth={2}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointSymbol={ChartPointSymbol}
            tooltip={ChartTooltip}
            sliceTooltip={ChartSliceTooltip}
            enableSlices="x"
            gridXValues={gridXValues}
            gridYValues={TICK_VALUES}
            crosshairType="cross"
            useMesh
          />
          <If condition={isLoadingAssessmentProgress || Boolean(assessmentProgressError)}>
            <div className="flex absolute top-0 left-0 flex-col justify-center items-center w-full h-full bg-white/90">
              <Container>
                <If condition={isLoadingAssessmentProgress}>
                  <Loading message="Loading..." />
                </If>
                <If condition={Boolean(assessmentProgressError)}>
                  <StyledError error={assessmentProgressError} />
                </If>
              </Container>
            </div>
          </If>
        </div>
        <If condition={chartData?.length > 0}>
          <div className="flex flex-row flex-wrap items-start ml-9">
            {chartData?.map((entry, index) => (
              <If condition={!entry.id.includes('_Low_Attempts')} key={entry.id + index.toString()}>
                <div className="flex flex-row items-center py-1 px-2 mt-2 mr-3 hover:bg-black/[0.03] rounded-sm cursor-default">
                  <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: graphColorDefaults.d3SchemaCategory10[index] }} />
                  <span className="text-[11px] font-semibold text-gray-600 whitespace-nowrap">{entry.id}</span>
                </div>
              </If>
            ))}
          </div>
        </If>
      </div>
    </WidgetContainer>
  );
}

export default GraphWidget;
