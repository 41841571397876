import React, { useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import FormatUtil from '../../utils/formatUtil';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import If from '../If/If';
import Leaderboard from '../Analytics/Leaderboard/Leaderboard';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import DateRangeFilter from './DateRangeFilter';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import DateRangeOptions from './DateRangeOptions';

// currently we are locking the date filter to 30 days,
// this will be removed in the future
const LOCK_DATE_FILTER = true;

const MEMBER_XP_LEADERBOARD_CONFIG = {
  key: 'member-xp',
  heading: 'Top Learners by XP',
  queryParams: {
    resultsPerPg: 10,
    sortCol: 'total_xp',
    sortDirection: 'desc',
  },
  href: '/reporting',
  isMember: true,
};

const getNormalizedTeamRole = (role) => {
  return role.replace('team-', '').replace('-', ' ');
};

const formatNumber = (col) => {
  return col.value ? FormatUtil.formatNumbers(col.value) : 0;
};

const getGroupString = (groups) => {
  return groups.map((group) => group.name).join(', ');
};

const TeamLeaderboard = inject(
  'userStore',
  'enterpriseStore'
)(
  observer(({ userStore, enterpriseStore }) => {
    const { team, user } = userStore;

    const { orgDashboardData } = enterpriseStore;

    const orgId = team?.id;

    const memberXpLeaderboardData = orgDashboardData?.leaderboards?.[MEMBER_XP_LEADERBOARD_CONFIG.key];

    const ignoredCols = memberXpLeaderboardData?.data?.columns
      ? [FormatUtil.getColIndex(memberXpLeaderboardData.data.columns, 'email'), FormatUtil.getColIndex(memberXpLeaderboardData.data.columns, 'groups')]
      : [];

    const renderColumn = useCallback(
      (row, fullRow, columns, _, colIdx) => {
        const column = columns[colIdx];

        if (row.value !== undefined && column.showCol) {
          const rowUserId = fullRow[0].value;

          if (column.key === 'name' && rowUserId === user?.id) {
            return <b>{row.value}</b>;
          }

          return row.value;
        }

        return '';
      },
      [user?.id]
    );

    const formatColumns = memberXpLeaderboardData?.data?.columns
      ? [
          {
            method: formatNumber,
            colIdx: FormatUtil.getColIndex(memberXpLeaderboardData.data, 'total_xp'),
          },
          {
            method: renderColumn,
            colIdx: FormatUtil.getColIndex(memberXpLeaderboardData.data, 'name'),
          },
        ]
      : [];

    const setDefaultDateFilters = () => {
      enterpriseStore.setDashboardDateFilter('orgDashboardData', 'startDate', DateRangeOptions['30days'].startDate);
      enterpriseStore.setDashboardDateFilter('orgDashboardData', 'endDate', DateRangeOptions['30days'].endDate);
      enterpriseStore.setDashboardDateFilter('orgDashboardData', 'range', '30days');
    };

    const getFilterableContent = () => {
      enterpriseStore.getDashboardLeaderboards('orgDashboardData', orgId, MEMBER_XP_LEADERBOARD_CONFIG.key, MEMBER_XP_LEADERBOARD_CONFIG);
    };

    const getLeaderboardData = () => {
      enterpriseStore.getGroupMeta('orgDashboardData', orgId);

      setDefaultDateFilters();

      getFilterableContent();

      userStore.setPreferredTeamInit('', orgId);
    };

    const applyDateFilter = (startDate, endDate, value) => {
      if (startDate) {
        enterpriseStore.setDashboardDateFilter('orgDashboardData', 'startDate', startDate);
      }

      if (endDate) {
        enterpriseStore.setDashboardDateFilter('orgDashboardData', 'endDate', endDate);
      }

      enterpriseStore.setDashboardDateFilter('orgDashboardData', 'range', value || null);

      getFilterableContent();
    };

    useEffect(() => {
      getLeaderboardData();
    }, [orgId]);

    return (
      <ErrorBoundary>
        <div className="flex justify-between items-end mt-8 -mb-2">
          <div className="flex flex-row items-center">
            <h2 className="mr-1 text-xl font-bold">Leaderboard</h2>
            <Tooltip triggerContent={<Icon className="inline-block w-4 h-4 text-zinc-400" name="information-circle" />} content="Leaderboard ranks are updated nightly" />
          </div>
          <div className="flex items-end">
            <div>
              <span className="mr-2 font-semibold text-black">Rank:</span>
              <span>{memberXpLeaderboardData?.data?.metaData?.learner_rank}</span>
              <span>/</span>
              <span>{memberXpLeaderboardData?.data?.totalRecords}</span>
            </div>
            <div className="flex items-end ml-5">
              <span className="mr-2 font-semibold text-black">Period:</span>
              <If condition={LOCK_DATE_FILTER}>
                <span>Last 30 days</span>
              </If>
              <If condition={!LOCK_DATE_FILTER}>
                <DateRangeFilter
                  dateChange={applyDateFilter}
                  dateRange={orgDashboardData.filters.range}
                  allTimeStart={team.created_at}
                  startDate={orgDashboardData.filters.startDate}
                  endDate={orgDashboardData.filters.endDate}
                  rangeSelectionClasses="w-full md:w-48 lg:w-52"
                  isPresetOnly
                  isCompact
                />
              </If>
            </div>
          </div>
        </div>
        <If condition={memberXpLeaderboardData}>
          <Leaderboard
            name="member"
            loading={memberXpLeaderboardData?.loading}
            error={memberXpLeaderboardData?.error}
            data={memberXpLeaderboardData?.data}
            filterValue={memberXpLeaderboardData?.queryParams?.sortCol}
            orgId={orgId}
            href={`/enterprise/${orgId}${memberXpLeaderboardData?.config?.href}`}
            formatColumns={formatColumns}
            ignoredCols={ignoredCols}
          />
        </If>
      </ErrorBoundary>
    );
  })
);

const TeamLeaderboardDrawer = inject(
  'commonStore',
  'userStore'
)(
  observer(({ commonStore, userStore }) => {
    const { team } = userStore;

    return (
      <If condition={team}>
        <ContentOverlay
          omitPadding
          open={commonStore.isLeaderboardDrawerOpen}
          dismiss={() => commonStore.setIsLeaderboardDrawerOpen(false)}
          width="w-full sm:w-[90vw] lg:w-[70vw]"
          maxWidth="max-w-2xl"
          ariaLabelledBy="dashboard-team-leaderboard"
          interactive={false}
        >
          <div className="px-2 pb-1 lg:py-4 lg:px-8">
            <div className="flex items-center">
              <If condition={team.logo_url}>
                <div className="mr-5">
                  <img className="w-18" src={team.logo_url} alt="Logo" />
                </div>
              </If>
              <div className="flex flex-col flex-1 items-start text-sm leading-7">
                <span className="text-lg font-bold">{team.name}</span>
                <If condition={team.member_count}>
                  <span className="text-gray-600">
                    <span className="font-semibold text-black">{FormatUtil.formatNumbers(team.member_count)}</span>
                    {team.member_count > 1 ? ' members' : ' member'}
                  </span>
                </If>
                <span className="text-gray-600">
                  <span className="font-semibold text-black">Created: </span>
                  {moment(team.created_at).format('MM/DD/YYYY')}
                </span>
              </div>
              <div className="flex flex-col flex-1 items-start py-2 pl-6 text-sm leading-7 border-l-1 border-stone-300">
                <span className="text-gray-600 capitalize">
                  <span className="font-semibold text-black">Role: </span>
                  {getNormalizedTeamRole(team.role)}
                </span>
                <span className="text-gray-600">
                  <span className="font-semibold text-black">License: </span>
                  {team.license_expires_at ? 'Yes' : 'No'}
                </span>
                <span className="text-gray-600">
                  <span className="font-semibold text-black">Groups: </span>
                  {team.user_groups.length > 0 ? getGroupString(team.user_groups) : 'None'}
                </span>
              </div>
            </div>
            <TeamLeaderboard />
          </div>
        </ContentOverlay>
      </If>
    );
  })
);

export default TeamLeaderboardDrawer;
