import React, { useMemo, useEffect } from 'react';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import Container from '../../Container/Container';
import BaselineEmptyText from '../../Baseline/modules/BaselineEmptyText';
import { OverviewHeader, OverviewLearnerTable } from '../../Baseline/BaselineTeamsPathOverview';

function AssesmentResultsTableWidget({ team, selectedContentDescription, selectedRole, downloadReport, downloadLoading }) {
  const orgId = team?.id;

  const pathId = selectedRole?.['content-id'];

  const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

  const { learners, isLoadingLearners, topicBreakdowns, isLoadingTopicBreakdown, actions } = useCybAssessmentPath();

  const currentPathLearners = useMemo(() => learners?.[pathId], [learners, pathId]);

  const currentPathLearnersCount = useMemo(() => {
    if (currentPathLearners?.default) {
      return currentPathLearners.default.length - 1;
    }
    return 0;
  }, [currentPathLearners]);

  const currentPathTopicBreakdown = useMemo(() => topicBreakdowns[contentDescriptionId], [topicBreakdowns, contentDescriptionId]);

  const isLoading = useMemo(() => isLoadingLearners || isLoadingTopicBreakdown, [isLoadingLearners, isLoadingTopicBreakdown]);

  useEffect(() => {
    if (orgId && contentDescriptionId) {
      if (!currentPathLearners) {
        actions.getLearners(orgId, pathId);
      }

      if (!currentPathTopicBreakdown) {
        actions.getTopicBreakdown(orgId, contentDescriptionId);
      }
    }
  }, [orgId, pathId, contentDescriptionId, currentPathLearners, currentPathTopicBreakdown]);

  return (
    <WidgetContainer className="flex flex-col pt-0" omitBackground omitBorder>
      <Header as="h3" className="-mb-2">
        Detail
      </Header>
      <If condition={isLoading}>
        <Container>
          <Loading message="Loading..." />
        </Container>
      </If>
      <If condition={!isLoading}>
        <If condition={!currentPathLearners || !currentPathTopicBreakdown}>
          <Container>
            <BaselineEmptyText orgId={orgId} />
          </Container>
        </If>
        <If condition={!!currentPathLearners && !!currentPathTopicBreakdown}>
          <div className="flex flex-col w-[calc(100%-3rem)]">
            <OverviewHeader team={team} topicBreakdown={currentPathTopicBreakdown} learnersCount={currentPathLearnersCount} />
            <OverviewLearnerTable
              orgId={orgId}
              pathId={pathId}
              learners={currentPathLearners}
              topicBreakdown={currentPathTopicBreakdown}
              downloadReport={downloadReport}
              downloadLoading={downloadLoading}
              key={contentDescriptionId}
            />
          </div>
        </If>
      </If>
    </WidgetContainer>
  );
}

export default AssesmentResultsTableWidget;
