import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { ProvideAuth } from '../../contexts/UseAuth';
import Router from '../Router/Router';
import PrimaryNavbar from '../Navigation/PrimaryNavbar';
import SidebarNav from '../Navigation/SidebarNav';
import AppInit from './AppInit';
import Footer from '../Footer/Footer';
import ThirdPartyJavascript from './ThirdPartyJavascript';
import EmailReminder from '../EmailReminder/EmailReminder';
import ToastMessage from '../Message/ToastMessage';
import ShareModal from '../Share/ShareModal';
import If from '../If/If';
import Confirm from '../Confirm/Confirm';
import GoalAchievementMessage from '../Navigation/GoalAchievementMessage';
import UpgradeLogo from './UpgradeLogo';
import TeamsSecondEmailModal from '../TeamsSecondEmailModal/TeamsSecondEmailModal';
import CybraryAdminTeamLarp from '../CybraryAdmin/CybraryAdminTeamLarp';

import './App.css';
import './App-print.css';
import '../Dashboard/SlickCarouselStyles.css';
import UsernameModal from '../Profile/UsernameModal/UsernameModal';
import CybAssessmentAnalyticsProvider from '../../providers/CybAssessmentAnalyticsProvider';
import CybAssessmentPathProvider from '../../providers/CybAssessmentPathProvider';
import CybAssessmentPathContentProvider from '../../providers/CybAssessmentPathContentProvider';
import LevelUpMessage from '../LevelUp/LevelUpMessage';
import ReferralInviteModal from '../ReferralModal/ReferralInviteModal';
import CookieModeButtons from '../Button/CookieModeButtons';
import ImmersiveProvider from '../../providers/ImmersiveProvider';
import BadgesModal from '../BadgeModal/BadgeModal';
import ForumsSidebar from '../Forums/ForumsSidebar';
import GlobalProviders from '../../providers/GlobalProviders';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import TeamToastNotifications from '../Enterprise/TeamToastNotifications';

const MainContent = inject(
  'userStore',
  'commonStore',
  'userNotificationStore'
)(
  observer(({ userStore, commonStore, userNotificationStore }) => {
    const { unread: hasUnread } = userNotificationStore;
    const { additionalClasses, additionalContainerClass, isInImmersive, hideFooter, confirmState, toastState } = commonStore;
    const location = useLocation();
    const isInBaseline = location.pathname.includes('/baseline/');
    const isInBrowseContentPage = location.pathname.startsWith('/browse/');
    const isLogOutPage = location.pathname.startsWith('/logout');
    const isOnboarding = location.pathname.startsWith('/onboarding');
    const isAuthPage = location.pathname.startsWith('/login') || location.pathname.startsWith('/register');
    const showPrimaryNav = !isInImmersive && Boolean(userStore.user);
    const omitFooter = isInImmersive || hideFooter;
    const addBottomPadding = hideFooter && !isInImmersive;

    const cybRootClasses = useMemo(() => {
      let classes = twMerge('cyb-app', 'bg-white');

      if (!isOnboarding && !isAuthPage) {
        classes = twMerge(classes, 'h-screen overflow-hidden');
      }

      return classes;
    }, [isOnboarding, isAuthPage]);

    const cybRouterClasses = useMemo(() => {
      let classes = twMerge('bg-white cyb-router', ...additionalClasses);

      // Use full screen height when no primary nav or immersive view
      if (isInImmersive || !commonStore.primaryNavDisplay || !userStore.user) {
        classes = twMerge(classes, 'h-screen');
      }

      if (isInBaseline) {
        // allows chart tooltips to display properly
        classes = twMerge(classes, 'overflow-visible');
      }

      return classes;
    }, [isInImmersive, isInBaseline, additionalClasses?.length, userStore.user, commonStore.primaryNavDisplay]);

    const cybContentClasses = useMemo(() => {
      // By default, we want to limit the width of the content to 1800px
      let classes = twMerge('mx-auto max-w-[1800px] flex flex-1 overflow-auto cyb-content', additionalContainerClass);
      if (isInBrowseContentPage || isInImmersive || isLogOutPage) {
        // On the browse content page, we want to limit the width to the screen size to allow the header to extend the full width of the screen
        classes = twMerge(classes, 'max-w-full');
      }
      if (isOnboarding || isAuthPage) {
        classes = twMerge(classes, 'overflow-visible');
      }
      return classes;
    }, [additionalContainerClass, isInBrowseContentPage, isInImmersive, isLogOutPage, isOnboarding, isAuthPage]);

    return (
      <ThirdPartyJavascript>
        <div className={cybRootClasses}>
          <GlobalProviders>
            {/** Let promo banners fail silently to the UI */}
            <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.EmptyFallbackDisplay}>
              <UpgradeLogo />
            </ErrorBoundary>
            <If condition={showPrimaryNav}>
              <PrimaryNavbar hasUnread={hasUnread} />
            </If>
            <ErrorBoundary>
              <div className="app-content">
                <CybAssessmentAnalyticsProvider userStore={userStore}>
                  <CybAssessmentPathProvider userStore={userStore}>
                    <CybAssessmentPathContentProvider>
                      <ImmersiveProvider userStore={userStore}>
                        <div className={cybContentClasses}>
                          <SidebarNav userStore={userStore} />
                          <div className={cybRouterClasses}>
                            <div className={twMerge('w-full', addBottomPadding && 'pb-28')}>
                              <Router />
                            </div>
                            {!omitFooter && <Footer omitTop={!userStore.user} />}
                          </div>
                        </div>
                      </ImmersiveProvider>
                    </CybAssessmentPathContentProvider>
                  </CybAssessmentPathProvider>
                </CybAssessmentAnalyticsProvider>
                <If condition={Boolean(userStore.user)}>
                  <>
                    <EmailReminder />
                    <GoalAchievementMessage immersiveView={isInImmersive} />
                    <Confirm
                      open={confirmState.confirmDisplay}
                      content={confirmState.content}
                      cancelButton={confirmState.cancelBtn}
                      confirmButton={confirmState.confirmBtn}
                      onCancel={confirmState.cancel}
                      onConfirm={confirmState.continue}
                      doNotShowAgainId={confirmState.doNotShowAgainId}
                      doNotShowAgainText={confirmState.doNotShowAgainText}
                      focusLockDelay={confirmState.focusLockDelay}
                      isLoading={confirmState.isLoading}
                      size="small"
                    />
                    <TeamsSecondEmailModal />
                    <CybraryAdminTeamLarp />
                    <UsernameModal />
                    <ReferralInviteModal />
                  </>
                </If>
                <LevelUpMessage />
                <ToastMessage active={toastState.toastDisplay} color={toastState.color} icon={toastState.icon} header={toastState.header} content={toastState.content} />
                <ShareModal />
                <BadgesModal />
              </div>
            </ErrorBoundary>
            <ForumsSidebar />
            <TeamToastNotifications />
          </GlobalProviders>
          <CookieModeButtons />
        </div>
      </ThirdPartyJavascript>
    );
  })
);

// FIXES A PROBLEM WITH GOOGLE TRANSLATE
// SEE: https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function removeChild(child) {
    if (child.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function insertBefore(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

const App = inject(
  'userStore',
  'commonStore',
  'authStore'
)(
  observer(({ userStore, commonStore, authStore }) => {
    return (
      <BrowserRouter>
        <ProvideAuth userStore={userStore} authStore={authStore}>
          <Helmet>
            <title>{commonStore.pageTitle}</title>
            {/** Default open graph social sharing meta data */}
            <meta property="og:title" content="Cybrary | Learn Cybersecurity Skills Online" />
            <meta
              property="og:description"
              content="Cybrary's accessible, affordable training platform provides curated career paths, threat-informed training, and certification preparation for professionals at all levels."
            />
            <meta
              property="og:image"
              content="https://images.ctfassets.net/kvf8rpi09wgk/6zC8AKNycm1GL0hxRxUPu1/37b33c7059bdf7bd3992bc7cb7d24537/Cybrary_Logo_2019-BlackCybrary.png"
            />
          </Helmet>
          <AppInit>
            <MainContent />
          </AppInit>
        </ProvideAuth>
      </BrowserRouter>
    );
  })
);

export default App;
