import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import WidgetContainer from '../../Container/WidgetContainer';
import RoleDropdown from '../../Dashboard/CybraryAssessmentsWidget/RoleDropdown';
import If from '../../If/If';
import LoadingSkeleton from '../../Loading/LoadingText';
import Checkbox from '../../FormFields/Checkbox';
import Title from '../../Title/Title';
import TableWidget from './TableWidget';
import GraphWidget from './GraphWidget';
import Dropdown from '../../Dropdown/Dropdown';

const SCORE_TYPES = [
  {
    text: 'Average',
    value: 'average',
  },
  {
    text: 'First',
    value: 'first',
  },
  {
    text: 'Best',
    value: 'best',
  },
];

function ScoreDropdown({ controlledValue, onChange }) {
  const onChangeHandler = useCallback(({ value }) => onChange(value), [onChange]);

  return (
    <div className="flex flex-col justify-center items-center">
      <Dropdown
        ariaLabel="Select a score type'"
        options={SCORE_TYPES}
        classes="text-xs sm:my-0 rounded z-20 min-w-[250px]"
        value={controlledValue.value}
        onChange={onChangeHandler}
        hideSelectedOptions={false}
        isTransparent={false}
        isCompact
      />
    </div>
  );
}

const AssessmentProgressWidget = inject(
  'userStore',
  'enterpriseStore'
)(
  observer(({ userStore, enterpriseStore, filters, groupId }) => {
    const team = userStore?.teamData;

    const orgId = team?.id;

    const { tracks } = useCybAssessmentAnalytics();

    const { topics: topicsIndex, isLoadingTopics, actions } = useCybAssessmentPath();

    const [contextMode, setContextMode] = useState('topic');

    const roles = useMemo(
      () =>
        tracks?.[userStore.user?.id]
          ?.filter((track) => track.status !== 'Unlisted')
          .concat()
          .reverse(),
      [tracks, userStore.user?.id]
    );

    const topics = useMemo(() => {
      const values = topicsIndex[orgId];

      if (!values) {
        return null;
      }

      return values;
    }, [topicsIndex, orgId]);

    const contentDropdownOptions = useMemo(() => {
      if (contextMode === 'topic') {
        return (
          topics?.map((topic) => ({
            'content-id': topic.id,
            'content-name': topic.title,
          })) || []
        );
      }
      return roles;
    }, [topics, roles, contextMode]);

    const [selectedScoreType, setSelectedScoreType] = useState(SCORE_TYPES[0]);

    const [selectedRole, setSelectedRole] = useState(roles?.[0] ?? null);

    const [selectedTopic, setSelectedTopic] = useState(topics?.[0] ?? null);

    const selectedContentDescription = useMemo(() => (contextMode === 'topic' ? selectedTopic : selectedRole), [contextMode, selectedRole, selectedTopic]);

    const baseQuery = useMemo(() => {
      const dateFilters = enterpriseStore.getDateFilterParams(filters.startDate, filters.endDate);
      return `${queryString.stringify(dateFilters)}`;
    }, [filters, enterpriseStore]);

    const onChangeScoreType = useCallback((scoreTypeId) => {
      const scoreType = SCORE_TYPES.find((item) => item.value === scoreTypeId);

      if (scoreType) {
        setSelectedScoreType(scoreType);
      }
    }, []);

    const onChangeContentDescription = useCallback(
      (contentDescriptionId) => {
        if (contextMode === 'topic') {
          const topic = topics?.find((item) => item.id === contentDescriptionId);

          if (topic) {
            setSelectedTopic(topic);
          }
        } else {
          const role = roles?.find((item) => item['content-id'] === contentDescriptionId);

          if (role) {
            setSelectedRole(role);
          }
        }
      },
      [roles, topics, contextMode]
    );

    const handleContextModeChange = useCallback(() => {
      setContextMode((prevState) => (prevState === 'role' ? 'topic' : 'role'));
    }, []);

    useEffect(() => {
      // onMount-ish (whenever the `roles` or `topics` value is initialized),
      // set the initial value for `selectedRole` or `selectedTopic` if it doesn't already exist
      if (contextMode === 'topic' && topics?.length > 0 && !selectedTopic) {
        setSelectedTopic(topics[0]);
      } else if (roles?.length > 0 && !selectedRole) {
        setSelectedRole(roles[0]);
      }
    }, [roles, topics, contextMode]);

    useEffect(() => {
      if (contextMode === 'topic' && !topics && !selectedTopic) {
        actions.getTopics(orgId);
      }
    }, [contextMode, orgId, topics, selectedTopic]);

    return (
      <WidgetContainer omitBackground omitPadding lightBorder>
        <div className="flex flex-row justify-between p-2 bg-gray-100 border-b-1 border-gray-400 lg:p-4">
          <div className="flex flex-col items-start">
            <Title title="Assessment Progress" wrapperClasses="my-0" classes="text-lg lg:text-lg font-bold mb-4" omitPadding />
            <p>How is my team performing on {contextMode}-based assessments?</p>
          </div>
          <div className="flex flex-col items-end">
            <div className="z-50 mb-4">
              <div className="flex flex-row items-center">
                <span className="mr-4 text-sm font-bold capitalize">score</span>
                <div className="relative">
                  <ScoreDropdown controlledValue={selectedScoreType} onChange={onChangeScoreType} />
                  <If condition={isLoadingTopics}>
                    <LoadingSkeleton wrapperClassName="absolute inset-0 p-0 z-50" className="w-full h-full rounded" />
                  </If>
                </div>
              </div>
            </div>
            <div className="flex z-40 flex-row justify-end items-center">
              <div className="flex flex-row items-center mr-8 text-sm">
                <p className="pr-2 mb-0 font-bold">By Role</p>
                <Checkbox className="flex items-start mr-4 mb-2" onChange={handleContextModeChange} checked={contextMode === 'topic'} ariaLabelledBy="toggle-context-mode" toggle />
                <p className="pl-2 mb-0 font-bold">By Topic</p>
              </div>
              <div className="flex flex-row items-center">
                <span className="mr-4 text-sm font-bold capitalize">{contextMode}</span>
                <div className="relative">
                  <RoleDropdown
                    optionData={contentDropdownOptions}
                    controlledValue={selectedContentDescription}
                    onChange={onChangeContentDescription}
                    isTransparent={false}
                    isCompact
                  />
                  <If condition={isLoadingTopics}>
                    <LoadingSkeleton wrapperClassName="absolute inset-0 p-0 z-50" className="w-full h-full rounded" />
                  </If>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WidgetContainer omitBackground omitBorder>
          <GraphWidget
            orgId={groupId ?? orgId}
            selectedScoreType={selectedScoreType}
            selectedContentDescription={selectedContentDescription}
            onChangeContentDescription={onChangeContentDescription}
            baseQuery={baseQuery}
          />
          <div className="my-5 mx-auto" />
          <TableWidget orgId={groupId ?? orgId} selectedContentDescription={selectedContentDescription} baseQuery={baseQuery} />
        </WidgetContainer>
      </WidgetContainer>
    );
  })
);

export default AssessmentProgressWidget;
