/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useCybAssessment } from '../../../../providers/CybAssessmentProvider';
import AssessmentTimer from './AssessmentTimer';

function QuestionsSidebar({ completeActivity }) {
  const { launched, hasAnswer, getTotalAnsweredQuestions, assessment, values, currentIndex, jumpToIndex, expires, submitAssessment, submitted } = useCybAssessment();
  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    setQuestions(assessment?.questions);
  }, [values, assessment]);

  const handleSubmit = async (force = false) => {
    await submitAssessment(() => completeActivity(), force);
  };

  if (submitted) {
    return null;
  }
  if (!launched) {
    return <p className="px-4 pt-10 text-sm text-center text-gray-700">Questions will show up here once the assessment has been started.</p>;
  }
  if (!questions?.length) {
    return <p className="px-4 pt-10 text-sm text-center text-gray-700">There are no questions to display.</p>;
  }

  const total = questions.length;
  const num = getTotalAnsweredQuestions();
  const allAnswered = num === total;

  const submitColor = allAnswered ? 'text-white bg-cyb-pink-500 hover:bg-cyb-pink-600' : 'text-gray-800 bg-gray-300 hover:bg-gray-400';

  return (
    <div>
      <div className="flex overflow-hidden z-10 justify-between items-center px-6 w-full h-16 bg-white border-b-xs border-gray-400 lg:fixed lg:top-0">
        <div className="text-base font-bold">Questions {`(${num}/${total})`}</div>
        {!!expires && <AssessmentTimer expires={expires - 1} onEnd={() => handleSubmit(true)} />}
        <button type="button" onClick={() => handleSubmit()} className={`py-2 px-6 text-base font-semibold text-center rounded-md cursor-pointer ${submitColor}`}>
          Submit
        </button>
      </div>
      <div className="px-6 pt-6 lg:mt-16">
        {questions.map((question, i) => {
          const id = question['question-id'];
          const answered = hasAnswer(id);
          const circleClasses = `rounded-full h-4 w-4 ml-1 mr-3 border-xs ${answered ? 'bg-cyb-pink-500 border-cyb-pink-500' : 'bg:cyb-white border-gray-600'}`;
          const buttonClasses = `w-full flex justify-between items-center py-2 text-gray-600 hover:bg-gray-200 cursor-pointer ${currentIndex === i ? 'bg-gray-200' : ''}`;
          return (
            <button onClick={() => jumpToIndex(i)} key={id} className={buttonClasses}>
              <div className="flex items-center">
                <div className={circleClasses} />
                <div className="flex-1">Question {i + 1}</div>
              </div>
              <div className="mr-1 text-right">1 point</div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default QuestionsSidebar;
