import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import WidgetContainer from '../../components/Container/WidgetContainer';
import RoleDropdown from '../../components/Dashboard/CybraryAssessmentsWidget/RoleDropdown';
import Loading from '../../components/Loading/Loading';
import Checkbox from '../../components/FormFields/Checkbox';
import If from '../../components/If/If';
import LoadingSkeleton from '../../components/Loading/LoadingText';
import StyledError from '../../components/Error/StyledError';
import AssessmentResultsGraphWidget from '../../components/Measure/Assessments/AssessmentResultsGraphWidget';
import AssesmentResultsTableWidget from '../../components/Measure/Assessments/AssesmentResultsTableWidget';
import useQueryParams from '../../hooks/useQueryParams';
import { isEnterpriseAdmin, isFreeOrCipUser } from '../../utils/navUtil';

const AssessmentResults = inject(
  'authStore',
  'userStore',
  'reportsStore',
  'commonStore'
)(
  observer(({ authStore, userStore, reportsStore, commonStore }) => {
    const team = userStore?.teamData;

    const orgId = team?.id;

    const { downloadReport, downloadLoading } = reportsStore;

    const navigate = useNavigate();

    const { memberId } = useQueryParams();

    const { tracks, error, isLoading: isLoadingTracks } = useCybAssessmentAnalytics();

    const { topics: topicsIndex, isLoadingTopics, actions } = useCybAssessmentPath();

    const [contextMode, setContextMode] = useState('topic');

    // Allow licensed team users and unlicensed team admins to access the assessments results page
    const isDeniedAccess = isFreeOrCipUser(userStore) && !isEnterpriseAdmin(userStore);

    const roles = useMemo(
      () =>
        tracks?.[userStore.user?.id]
          ?.filter((track) => track.status !== 'Unlisted')
          .concat()
          .reverse(),
      [tracks, userStore.user?.id]
    );

    const topics = useMemo(() => {
      const values = topicsIndex[orgId];

      if (!values) {
        return null;
      }

      return values;
    }, [topicsIndex, orgId]);

    const contentDropdownOptions = useMemo(() => {
      if (contextMode === 'topic') {
        return (
          topics?.map((topic) => ({
            'content-id': topic.id,
            'content-name': topic.title,
          })) || []
        );
      }
      return roles;
    }, [topics, roles, contextMode]);

    const [selectedRole, setSelectedRole] = useState(roles?.[0] ?? null);

    const [selectedTopic, setSelectedTopic] = useState(topics?.[0] ?? null);

    const selectedContentDescription = useMemo(() => (contextMode === 'topic' ? selectedTopic : selectedRole), [contextMode, selectedRole, selectedTopic]);

    const onChangeContentDescription = useCallback(
      (contentDescriptionId) => {
        if (contextMode === 'topic') {
          const topic = topics?.find((item) => item.id === contentDescriptionId);

          if (topic) {
            setSelectedTopic(topic);
          }
        } else {
          const role = roles?.find((item) => item['content-id'] === contentDescriptionId);

          if (role) {
            setSelectedRole(role);
          }

          if (memberId) {
            navigate(window.location.pathname);
          }
        }
      },
      [roles, topics, memberId, contextMode]
    );

    const handleContextModeChange = useCallback(() => {
      setContextMode((prevState) => (prevState === 'role' ? 'topic' : 'role'));
    }, []);

    useEffect(() => {
      if (isDeniedAccess) {
        navigate('/measure/assessments');
      } else {
        commonStore.setPageTitle('Measure - Assessment Results | Cybrary');
        authStore.fireAttributionEvent();
      }
    }, [isDeniedAccess]);

    useEffect(() => {
      // onMount-ish (whenever the `roles` or `topics` value is initialized),
      // set the initial value for `selectedRole` or `selectedTopic` if it doesn't already exist
      if (contextMode === 'topic' && topics?.length > 0 && !selectedTopic && !isDeniedAccess) {
        setSelectedTopic(topics[0]);
      } else if (roles?.length > 0 && !selectedRole && !isDeniedAccess) {
        setSelectedRole(roles[0]);
      }
    }, [roles, topics, contextMode, isDeniedAccess]);

    useEffect(() => {
      if (contextMode === 'topic' && !topics && !selectedTopic) {
        actions.getTopics(orgId);
      }
    }, [contextMode, orgId, topics, selectedTopic]);

    return (
      <Container className="pt-0 sm-576:px-4" md omitPadding>
        <div className="flex flex-row justify-between items-center">
          <Title title="Assessment Results" omitPadding wrapperClasses="my-8" classes="text-2xl lg:text-2xl" />
          <div className="flex print:hidden justify-end items-center text-sm">
            <p className="pr-2 pb-2 mb-0 font-bold">By Role</p>
            <Checkbox toggle onChange={handleContextModeChange} checked={contextMode === 'topic'} ariaLabelledBy="toggle-context-mode" />
            <p className="pb-2 pl-2 mb-0 font-bold">By Topic</p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col py-8 px-14 mb-8 text-[15px] bg-gray-100">
            <p>Review your Team&apos;s assessment results to gain insights into their strengths and weaknesses by topic.</p>
          </div>
          <If condition={isLoadingTracks}>
            <Loading message="Loading..." />
          </If>
          <If condition={error}>
            <StyledError error={error} />
          </If>
          <If condition={!isLoadingTracks && !error}>
            <WidgetContainer omitPadding omitBorder omitBackground>
              <div className="flex absolute top-0 right-0 z-20 flex-row items-center mt-4 mr-4">
                <span className="mr-2 font-bold capitalize">{contextMode}:</span>
                <div className="relative">
                  <RoleDropdown optionData={contentDropdownOptions} controlledValue={selectedContentDescription} onChange={onChangeContentDescription} isCompact />
                  <If condition={isLoadingTopics}>
                    <LoadingSkeleton wrapperClassName="absolute inset-0 p-0 z-50" className="w-full h-full rounded" />
                  </If>
                </div>
              </div>
              <AssessmentResultsGraphWidget orgId={orgId} selectedContentDescription={selectedContentDescription} onChangeContentDescription={onChangeContentDescription} />
              <div className="my-5 mx-auto" />
              <AssesmentResultsTableWidget
                team={team}
                selectedContentDescription={selectedContentDescription}
                selectedRole={selectedRole}
                downloadReport={downloadReport}
                downloadLoading={downloadLoading}
              />
            </WidgetContainer>
          </If>
        </div>
      </Container>
    );
  })
);

export default AssessmentResults;
