import React from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '../../Icon/Icon';

// Trophy icon with rounded a gradient background
function GradientTrophyIcon({ containerClasses, iconClasses }) {
  const parentClasses = twMerge('flex justify-center mx-auto w-18 bg-gradient-to-br from-[#5E2C90] to-cyb-pink-500 rounded-full', containerClasses);
  const childClasses = twMerge('p-2 my-2 w-14 h-14 text-white fill-current', iconClasses);
  return (
    <div className={parentClasses}>
      <Icon name="trophy" className={childClasses} />
    </div>
  );
}

export default GradientTrophyIcon;
