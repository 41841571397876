import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import If from '../../../If/If';
import ProgressBar from '../../../ProgressBar/ProgressBar';

/**
 * Badge container to control adding/removing a button wrapper when we have an onClick event
 * @param {function} onClick - (optional) function to call when the badge is clicked
 * @param {string} className - (optional) classNames for the badge
 * @param {string} children - children to render in the badge
 * @param {number} tabIndex - (optional) tabIndex for the badge
 * @returns <BadgeContainer /> the badge container
 */
function BadgeContainer({ onClick, className, children, tabIndex }) {
  // if we have an onclick, wrap the badge in a button
  if (onClick) {
    return (
      <button type="button" onClick={onClick} className={className} tabIndex={tabIndex}>
        {children}
      </button>
    );
  }
  // otherwise just return children in a container with the same styles
  return <div className={className}>{children}</div>;
}

/**
 * Individual Badge component
 * @param {object} badge - object with the badge data (name, description, image url, etc.
 * @param {string} className - (optional) classNames for the badge
 * @param {string} wrapperClassName - (optional) classNames for the badge wrapper
 * @param {string} imgClassName - (optional) classNames for the badge image
 * @param {boolean} noBorder - (optional) boolean to hide the border around the badge
 * @param {boolean} noTitle - (optional) boolean to hide the title text
 * @param {boolean} noDescription - (optional) boolean to hide the description text
 * @param {boolean} alwaysUnlocked - (optional) boolean to always show the unlocked badge state
 * @param {function} onClick - (optional) function to call when the badge is clicked
 * @param {boolean} showProgress - (optional) boolean to show the progress bar
 * @param {string} progressWrapperClassName - (optional) classNames for the progress bar wrapper
 * @param {number} tabIndex - (optional) tabIndex for the badge
 * @param {boolean} isNewlyEarnedBadge - (optional) boolean to show the date the badge was earned and 100% progress
 */
function Badge({
  badge,
  className,
  wrapperClassName,
  imgClassName,
  noBorder,
  noTitle,
  noDescription,
  alwaysUnlocked,
  onClick,
  showProgress,
  progressWrapperClassName,
  tabIndex,
  isNewlyEarnedBadge,
}) {
  // If we don't have a badge, return null.
  if (!badge) return null;
  const { id, name, description, locked_image_url, unlocked_image_url, completed_at, progress } = badge;
  let progressValue = progress;

  // If we don't have 100 progress and the badge isNewlyEarned, set the progress to 100
  // This is used when profile badge data is cached/missing and we get a new badge from a notification
  if (progress !== 100 && isNewlyEarnedBadge) {
    progressValue = 100;
  }

  // By default use the unlocked image url in an earned state
  let imgSrc = unlocked_image_url;
  let imgSaturation = 'saturate-100';
  let textColor = 'text-black';
  let cursor = 'cursor-default';

  // Change styles if the badge has not been earned
  if (!completed_at && !alwaysUnlocked && !isNewlyEarnedBadge) {
    // If we have a custom locked image url, use that, otherwise use the unlocked image url
    imgSrc = locked_image_url || unlocked_image_url;
    // If we have a custom locked image url, use 100% saturation, otherwise use 0% saturation to gray out the unlocked image
    imgSaturation = locked_image_url ? 'saturate-100' : 'saturate-0';
    // Gray out the text
    textColor = 'text-gray-500';
    // Change the cursor to blocked
    cursor = 'cursor-not-allowed';
  }

  // Change cursor to pointer if we have an onClick function
  if (onClick) {
    cursor = 'cursor-pointer';
  }

  // Hide border when `noBorder` prop is passed
  const border = noBorder ? '' : 'border border-gray-400';

  const badgeContainerClasses = useMemo(() => twMerge('w-full', imgSaturation, cursor, wrapperClassName), [imgSaturation, cursor, wrapperClassName]);
  const badgeClasses = useMemo(
    () => twMerge('relative flex flex-col justify-evenly items-center p-2 w-full text-lg lg:text-2xl font-black align-middle h-[200px] lg:h-[250px]', border, className),
    [border, className]
  );
  const badgeImageContainerClasses = useMemo(() => twMerge('flex items-center max-w-[60px] h-[40px] lg:max-w-[120px] lg:h-[80px] align-middle', imgClassName), [imgClassName]);
  const badgeProgressBarClasses = useMemo(() => twMerge('absolute bottom-4 px-4 w-full text-left', progressWrapperClassName), [progressWrapperClassName]);

  return (
    <BadgeContainer className={badgeContainerClasses} onClick={onClick} tabIndex={tabIndex}>
      <div id={`profile-badge-${id}`} className={badgeClasses}>
        <div className={badgeImageContainerClasses}>
          <img
            src={imgSrc || 'https://images.ctfassets.net/kvf8rpi09wgk/3CLcU72kFbILMpNPbWHCj5/89ab3f6394b49eecac5fbd45999196ec/Pen-Tester_Badge_Learn.png'}
            alt={name}
            className="max-h-[80px]"
          />
        </div>
        <If condition={!noTitle}>
          <h3 className={`flex justify-center items-center min-h-[3.5rem] text-sm lg:text-base text-center ${textColor}`}>{name}</h3>
        </If>
        <If condition={!noDescription}>
          <p className={`flex justify-center items-center min-h-[2.4rem] text-xs lg:text-sm font-normal text-center ${textColor}`}>{description}</p>
        </If>
        <If condition={showProgress}>
          <div className="pt-2 w-full" />
          <div className={badgeProgressBarClasses}>
            <span className="pb-2 w-full text-xs font-normal text-left">{progressValue}% Complete</span>
            <ProgressBar progress={progressValue} wrapperClassName="w-full h-3" progressClassName="h-3" />
          </div>
        </If>
      </div>
    </BadgeContainer>
  );
}

export default Badge;
