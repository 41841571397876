import React, { useEffect, useRef } from 'react';
import HeaderPromoBanner from '../../Banners/HeaderPromoBanner';
import RichTextRenderer from './RichTextRenderer';
import { useCybAssessment } from '../../../providers/CybAssessmentProvider';
import LockedContainer from '../../Container/LockedContainer';

export default function CaseStudyContent({ generalFrame, userStore, activity }) {
  const contentContainer = useRef(null);
  const { launched } = useCybAssessment();

  // Make sure we scroll to the top if we are switching between case studies
  useEffect(() => {
    setTimeout(() => {
      if (contentContainer && contentContainer.current) {
        contentContainer.current.focus();
      }
    }, 400);
  }, [contentContainer, contentContainer?.current]);

  // Add a dark bg until we've launched
  const bgClass = !launched ? 'bg-gray-500' : '';

  return (
    <div className={`immersive-2023-lesson-frame overflow-hidden ${generalFrame}`}>
      <div className="flex overflow-hidden relative justify-center h-full">
        <div className="pt-0 w-full bg-white transition-all duration-300">
          <div className="overflow-hidden w-full h-full">
            <div tabIndex="-1" ref={contentContainer} className="box-content overflow-y-scroll w-full h-full outline-none">
              <HeaderPromoBanner inline />
              <LockedContainer id="case-study-container" isLocked={!launched} isFullWidth showLock={false}>
                <div className={`py-4 px-2 sm:py-10 md:px-12 ${bgClass}`}>
                  <div className="mx-auto max-w-[750px]">
                    <RichTextRenderer userStore={userStore} content={activity.case_study_content.content} />
                  </div>
                </div>
              </LockedContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
