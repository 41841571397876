import React from 'react';
import { twMerge } from 'tailwind-merge';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

function Title({ title, classes, breadcrumbs, wrapperClasses, omitPadding, titleId }) {
  const wrapperClassName = `${!omitPadding ? 'pt-8 md:pt-12 pb-4' : ''} ${wrapperClasses || ''}`;

  return (
    <div className={wrapperClassName}>
      <Breadcrumbs crumbs={breadcrumbs} />
      <h1 id={titleId || ''} className={twMerge('font-black text-4xl lg:text-5xl m-0', classes)}>
        {title}
      </h1>
    </div>
  );
}

export default Title;
