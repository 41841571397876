import React, { Component, useState } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { twMerge } from 'tailwind-merge';
import Collapsible from 'react-collapsible';
import CircularProgress from '../ProgressBar/CircularProgress';
import GoalProgressExpander from './GoalProgressExpander';
import ProgressBarDisplayLabel from './ProgressBarDisplayLabel';
import PracticeTestDisplayLabel from './PracticeTestDisplayLabel';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AddLink from '../AddLink/AddLink';
import PermalinkUtil from '../../utils/permalinkUtil';
import GoalsUtil from '../../utils/goalsUtil';
import Title from '../Title/Title';
import Tooltip from '../Tooltip/Tooltip';
import HelpIcon from '../../Icons/HelpIcon';
import ChevronDown from '../../Icons/ChevronDown';
import WizardTimeframeSelection from './WizardTimeframeSelection';
import WizardStartTimeSelection from './WizardStartTimeSelection';
import WizardParticipantsSelection from './WizardParticipantsSelection';
import WizardOwnersSelection from './WizardOwnersSelection';
import AvatarsList from '../Avatar/AvatarsList';
import RecurringGoalIcon from './RecurringGoalIcon';
import Icon from '../Icon/Icon';
import FormatUtil from '../../utils/formatUtil';
import { HELP_DESK_LINKS } from '../../constants';
import If from '../If/If';

function GoalTitle({ value, setValue, editView, isBaseliningGoal }) {
  if (editView) {
    let inputValue = value;

    if (isBaseliningGoal) {
      // Baselining Goal - Pre-Assessment > Baselining Goal
      [inputValue] = inputValue.split(' - ');
    }

    return (
      <input className="p-2 w-full text-sm rounded-sm border border-gray-400" value={inputValue} onChange={(e) => setValue('name', e.target.value)} placeholder="Name your goal" />
    );
  }

  return (
    <h2 id="goal-title" className="text-2xl font-black">
      {value}
    </h2>
  );
}

function GoalDescription({ value, setValue, editView }) {
  const sectionTitleClasses = 'text-gray-600 text-2xs font-semibold uppercase mb-2';
  if (editView) {
    return (
      <>
        <p className={sectionTitleClasses}>Details</p>
        <textarea
          className="p-2 mb-8 w-full text-sm rounded-sm border border-gray-400"
          value={value || ''}
          onChange={(e) => setValue('description', e.target.value)}
          placeholder="Add details"
        />
      </>
    );
  }

  return (
    value && (
      <>
        <p className={sectionTitleClasses}>Details</p>
        <p className="mb-8 text-sm">{value}</p>
      </>
    )
  );
}

function GoalStartTimeFrame({ startDate, setValue, editView, goalType, hasStarted }) {
  const [open, setOpen] = useState(false);

  if (editView && !hasStarted) {
    return (
      <div className="p-2 mb-8 rounded-sm border border-gray-400">
        <Collapsible
          triggerTagName="div"
          open={open}
          handleTriggerClick={() => setOpen(!open)}
          trigger={
            <div className="text-sm text-center cursor-pointer">
              {startDate ? moment.utc(startDate).format('MM/DD/YYYY') : 'Today'}
              <button aria-label="Toggle start goal time frame selection">
                <ChevronDown classes={`w-4 h-4 transition-transform inline-block ml-2 ${open ? 'transform rotate-180' : ''}`} />
              </button>
            </div>
          }
          transitionTime={250}
          transitionCloseTime={250}
        >
          <div className="overflow-y-auto mt-2" style={{ maxHeight: '20rem' }}>
            <WizardStartTimeSelection setValue={setValue} selectedStartDate={startDate} goalType={goalType} />
          </div>
        </Collapsible>
      </div>
    );
  }
  return <p className="mb-8 text-sm">{startDate ? `${moment.utc(startDate).format('MM/DD/YYYY')}` : 'None'}</p>;
}

function GoalOwners({ editView, setValue, filterOptions, selectedOwners, selectedOwnerIds, owners, userId }) {
  const [open, setOpen] = useState(false);
  if (editView) {
    return (
      <div className="p-2 mb-8 rounded-sm border border-gray-400">
        <Collapsible
          triggerTagName="div"
          open={open}
          handleTriggerClick={() => setOpen(!open)}
          trigger={
            <div className="text-sm text-center cursor-pointer">
              {selectedOwnerIds.length} {FormatUtil.pluralize(selectedOwnerIds.length, 'owner')}
              <button aria-label="Toggle goal owners selection">
                <ChevronDown classes={`w-4 h-4 transition-transform inline-block ml-2 ${open ? 'transform rotate-180' : ''}`} />
              </button>
            </div>
          }
          transitionTime={250}
          transitionCloseTime={250}
        >
          <div className="overflow-y-auto relative mt-2" style={{ maxHeight: '20rem' }}>
            <WizardOwnersSelection setValue={setValue} owners={owners} selectedOwnerIds={selectedOwnerIds} filterOptions={filterOptions} userId={userId} />
          </div>
        </Collapsible>
      </div>
    );
  }

  return <AvatarsList stacked data={selectedOwners} avatarSizes="w-6 h-6" />;
}

function GoalTimeframe({ dueType, savedDueType, dueDate, setValue, editView, goalType, goalOutcomeType, dueInterval }) {
  const [open, setOpen] = useState(false);

  const goalIsRecurring = savedDueType === 'recurring';

  const isBaseliningGoal = goalOutcomeType.includes('baselining_');

  if (editView) {
    return (
      <div className={`p-2 mb-8 rounded-sm border border-gray-400 ${isBaseliningGoal ? ' bg-gray-200' : ''}`}>
        <Collapsible
          triggerTagName="div"
          open={open}
          handleTriggerClick={() => (isBaseliningGoal ? false : setOpen(!open))}
          trigger={
            <div className="text-sm text-center cursor-pointer">
              {dueDate ? moment.utc(dueDate).format('MM/DD/YYYY') : 'None'}
              <If condition={!isBaseliningGoal}>
                <button aria-label="Toggle goal time frame selection">
                  <ChevronDown classes={`w-4 h-4 transition-transform inline-block ml-2 ${open ? 'transform rotate-180' : ''}`} />
                </button>
              </If>
            </div>
          }
          transitionTime={250}
          transitionCloseTime={250}
        >
          <div className="overflow-y-auto relative mt-2" style={{ maxHeight: '20rem' }}>
            <WizardTimeframeSelection
              setValue={setValue}
              selectedDueType={dueType}
              selectedDueDate={dueDate}
              selectedDueInterval={dueInterval}
              goalType={goalType}
              disableRecurringOption={!goalIsRecurring}
              isEditView={editView}
            />
          </div>
        </Collapsible>
      </div>
    );
  }

  return (
    <div className="mb-8 text-sm">
      <p className="mb-0">{dueDate ? `${moment.utc(dueDate).format('MM/DD/YYYY')}` : 'None'}</p>
      {goalIsRecurring && (
        <div className="flex items-center">
          <p className="mb-0">{GoalsUtil.getDeadlineDetails(dueType, dueDate, dueInterval)}</p>
          <RecurringGoalIcon />
        </div>
      )}
    </div>
  );
}

function GoalParticipants({ displayValue, setValue, selectedParticipantType, selectedParticipantIds, members, groups, team, filterOptions, searchGroups, hidden, includeAdmins }) {
  if (hidden) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const sectionTitleClasses = 'text-gray-600 text-2xs font-semibold uppercase mb-2';
  return (
    <div className="mb-8">
      <p className={sectionTitleClasses}>Participants</p>
      <div className="p-2 rounded-sm border border-gray-400">
        <Collapsible
          triggerTagName="div"
          open={open}
          handleTriggerClick={() => setOpen(!open)}
          trigger={
            <div className="text-sm text-center cursor-pointer">
              {displayValue}
              <button aria-label="Toggle goal participants">
                <ChevronDown classes={`w-4 h-4 transition-transform inline-block ml-2 ${open ? 'transform rotate-180' : ''}`} />
              </button>
            </div>
          }
          transitionTime={250}
          transitionCloseTime={250}
        >
          <div className="overflow-y-auto mt-2" style={{ maxHeight: '20rem' }}>
            <WizardParticipantsSelection
              setValue={setValue}
              selectedParticipantType={selectedParticipantType}
              selectedParticipantIds={selectedParticipantIds}
              members={members}
              groups={groups}
              searchGroups={searchGroups}
              team={team}
              filterOptions={filterOptions}
              includeAdmins={includeAdmins}
            />
          </div>
        </Collapsible>
      </div>
    </div>
  );
}

function GoalEditActions({ showError, error, editCancel, saveGoal, isValid }) {
  return (
    <div className="flex justify-between items-center pt-4 mt-4 border-t border-gray-400">
      <div>
        {showError && (
          <div className="flex items-center px-8 text-sm text-cyb-red-700">
            <Icon name="exclamation-circle" className="shrink-0 mr-2 w-6 h-6" /> {error}
          </div>
        )}
      </div>
      <div className="flex justify-end items-center py-4 px-8">
        <button className="mr-4 text-sm underline cursor-pointer" onClick={editCancel}>
          Cancel
        </button>
        <div>
          <button
            onClick={() => saveGoal(isValid)}
            className={`rounded-sm bg-cyb-pink-500 text-white font-bold text-center hover:text-white hover:bg-pink-600 leading-5 py-2.5 px-6 text-sm ${
              !isValid ? 'opacity-50' : ''
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

function GoalInstructionalText({ practiceTest, metric, isLearnerDecides, outcomeType, hidden }) {
  if (hidden) {
    return null;
  }
  const learnerDecidesBullet = <li className="mb-2">You can complete this goal with any {metric === 'ceus' ? 'courses' : 'learning activities'} from our catalog</li>;
  const continueLearningBullet = (
    <li className="mb-2">
      Click <em>Continue Learning</em> to view the assigned learning activities
    </li>
  );
  const containerClasses = 'mt-8 xl:mt-0 lg:flex-1 text-sm p-6 border border-gray-400 rounded-sm';
  const headingClasses = 'text-xl font-bold mb-4';
  if (metric === 'certification' && practiceTest && practiceTest.permalink) {
    return (
      <div className={containerClasses}>
        <h3 className={headingClasses}>Complete your goal with a practice test</h3>
        <ul className="px-6 mb-4 leading-6 list-disc">
          <li className="mb-2">Assess how well you would do on an actual certification exam (80 or higher is required to pass).</li>
          <li className="mb-2">Retake the practice test as many times as you need to pass</li>
          <li className="mb-2">
            Practice test results post back to Cybrary within 24 hours
            <Tooltip
              triggerContent={<HelpIcon classes="w-4 h-4 inline-block" />}
              content={
                <p>
                  Progress and Completion information is updated on different schedules for each vendor on the platform. Click{' '}
                  <AddLink to={HELP_DESK_LINKS.VENDOR_REPORTING_PROGRESS}>here</AddLink> for more information.
                </p>
              }
            />
          </li>
        </ul>
        <AddLink to={PermalinkUtil.formatInApp(practiceTest.permalink)}>
          <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">Take Practice Test</button>
        </AddLink>
      </div>
    );
  }
  if (metric === 'ceus') {
    return (
      <div className={containerClasses}>
        <h3 className={headingClasses}>Complete your goal by earning CEUs</h3>
        <ul className="px-4 list-disc">
          {isLearnerDecides ? learnerDecidesBullet : continueLearningBullet}
          <li>Cybrary awards 1 CEU for every 1 hour of video learning in a course</li>
        </ul>
      </div>
    );
  }
  if (metric === 'learning_hours') {
    return (
      <div className={containerClasses}>
        <h3 className={headingClasses}>Complete your goal by earning learning hours</h3>
        <ul className="px-4 list-disc">
          {isLearnerDecides ? learnerDecidesBullet : continueLearningBullet}
          <li>Cybrary awards learning hours based on the length of the learning activity</li>
        </ul>
      </div>
    );
  }
  if (metric === 'activities' && outcomeType === 'onboarding') {
    return (
      <div className={containerClasses}>
        <h3 className={headingClasses}>Complete your goal by learning the skills necessary for your new role</h3>
        <ul className="px-4 list-disc">
          {continueLearningBullet}
          <li>Complete all the learning activities to finish your onboarding</li>
        </ul>
      </div>
    );
  }
  if (metric === 'activities' && outcomeType === 'other') {
    return (
      <div className={containerClasses}>
        <ul className="px-4 list-disc">
          {continueLearningBullet}
          <li>Complete all the learning activities to finish your goal</li>
        </ul>
      </div>
    );
  }

  return null;
}

function ProgressMetricsSelector({ changeMetric, selectedMetric, outcomeSettings, hasPracticeTest, outcomeType }) {
  const outcomeSettingsKeys = outcomeSettings ? Object.keys(outcomeSettings) : [];
  const hasCeusOutcome = outcomeSettingsKeys.indexOf('ceus_total') > -1;
  const hasLearningHoursOutcome = outcomeSettingsKeys.indexOf('learning_seconds_total') > -1;
  const selectorClasses = 'text-gray-600 text-xs cursor-pointer';
  const activeClasses = 'text-black text-xs font-bold';
  const showDivider = outcomeSettingsKeys.indexOf('activities_total') > -1 && (hasPracticeTest || hasCeusOutcome || hasLearningHoursOutcome);
  const isProgramOutcome = outcomeType.includes('_program');
  return (
    <div className="leading-3">
      <span className="text-xs text-gray-600">Show by: </span>
      {hasPracticeTest && (
        <button className={`${selectedMetric === 'certification' ? activeClasses : selectorClasses} `} onClick={() => changeMetric('certification')}>
          Practice Tests
        </button>
      )}
      {hasCeusOutcome && (
        <button className={`${selectedMetric === 'ceus' ? activeClasses : selectorClasses} `} onClick={() => changeMetric('ceus')}>
          Total CEUs
        </button>
      )}
      {hasLearningHoursOutcome && (
        <button className={`${selectedMetric === 'learning_hours' ? activeClasses : selectorClasses} `} onClick={() => changeMetric('learning_hours')}>
          Total Hours
        </button>
      )}
      {showDivider && <span className="text-xs text-gray-600"> | </span>}
      {outcomeSettingsKeys.indexOf('activities_total') > -1 && (
        <button className={`${selectedMetric === 'activities' ? activeClasses : selectorClasses} `} onClick={() => changeMetric('activities')}>
          {isProgramOutcome ? 'Requirements Progress' : 'Learning Activity'}
        </button>
      )}
    </div>
  );
}

function GoalProgress({ teamAdminView, teamGroupId, goalData, metricView, goalsStore, groupScopeQuery, hasPracticeTest, teamId }) {
  if (!metricView || !goalData || !goalData.data || !goalData.data.progress) {
    return null;
  }

  const isProgramType = goalData?.data?.outcome_type?.includes('_program');

  // do not show expandable content when in a demo
  // this results in additional user data requests that fail
  if (teamAdminView && goalData.data.id !== 'demo') {
    return (
      <GoalProgressExpander
        teamGroupId={teamGroupId}
        teamId={teamId}
        goalId={goalData.data.id}
        goalName={goalData.data.name}
        outcomeType={goalData.data.outcome_type}
        participantType={goalData.data.participant_type}
        participants={goalData.data.participants}
        progress={goalData.data.progress}
        totals={goalData.data.outcome_settings}
        practiceTest={goalData.data.certification_test}
        groupScopeQuery={groupScopeQuery}
        progressMetric={metricView}
        isProgramType={isProgramType}
      />
    );
  }

  const preppedOutcomeSettings = goalsStore.prepGoalTotals(goalData.data.progress, goalData.data.outcome_settings, goalData.data.outcome_type);
  let progressPercentage = goalsStore.getProgressPercentage(goalData.data.progress, preppedOutcomeSettings, metricView, isProgramType);
  const isCertificationGoalWithTest = metricView === 'certification' && hasPracticeTest;
  if (isCertificationGoalWithTest) {
    progressPercentage = 1 * goalData.data.progress.percent_completed;
  }
  const progressSuffix = goalsStore.getProgressSuffix(
    goalData.data.progress[`${metricView}_completed`],
    preppedOutcomeSettings,
    metricView,
    goalData.data.progress.participation_count,
    metricView === 'activities'
  );
  const progressDisplay = goalsStore.getProgressDisplay(goalData.data.progress, preppedOutcomeSettings, metricView);

  const progressLabelClasses = `block ${metricView === 'activities' ? 'text-4xl' : 'text-5xl'} font-black`;
  return (
    <CircularProgress strokeWidth={8} percentageValue={progressPercentage}>
      {isCertificationGoalWithTest ? (
        <PracticeTestDisplayLabel
          attempts={goalData.data.progress.attempts}
          statusMessage={GoalsUtil.getPracticeTestStatus(goalData.data)}
          passed={progressPercentage === 100}
          highScore={goalData.data.progress.score}
        />
      ) : (
        <>
          <ProgressBarDisplayLabel
            goalProgressDisplay={progressDisplay}
            goalProgressPercentage={progressPercentage}
            goalProgressSuffix={metricView === 'activities' && progressSuffix}
            wrapperClasses="text-center"
            progressClasses={progressLabelClasses}
            percentageOnly={isProgramType}
          />
          {metricView !== 'activities' && <div className="mt-1 text-2xs font-semibold text-gray-600">{progressSuffix}</div>}
        </>
      )}
    </CircularProgress>
  );
}

const GoalDetails = inject(
  'goalsStore',
  'userStore',
  'commonStore',
  'enterpriseStore',
  'teamHierarchyStore'
)(
  observer(
    class GoalDetails extends Component {
      state = {
        metricView: null,
        submitting: false,
        validationError: null,
        showError: false,
        recurringWarning: false,
        savedGoalData: {},
      };

      componentDidMount() {
        // skip getting goal data from api when in demo view
        if (this.props.goalId !== 'demo') {
          this.getGoalData();
        } else {
          this.props.goalsStore.enterDemo();
          // we still need to set the default metric view when in a demo
          // since this is usually called in getGoalData()
          this.setDefaultMetricView();
        }
      }

      componentWillUnmount() {
        // to avoid clearing the demo data when switching between goals
        // this goalData is already reset upon exiting the demo through the goalsStore
        if (this.props.goalId !== 'demo') {
          this.props.goalsStore.setDefaultGoalData();
        }
      }

      getGoalData = () => {
        this.props.goalsStore.getGoalData(this.props.goalId, this.props.progressTeamGroupId).then(() => {
          const { goalData } = this.props.goalsStore;
          // Set the existing goal data in state for watching for certain changes
          const newState = {
            ...this.state,
            savedGoalData: goalData && goalData.data ? cloneDeep(goalData.data) : {},
          };
          this.setState(newState);
          this.setDefaultMetricView();
        });
      };

      setDefaultMetricView = () => {
        const { goalData } = this.props.goalsStore;
        this.changeMetricView(GoalsUtil.getPrimaryGoalMetric(goalData.data));
      };

      changeMetricView = (metric) => {
        if (metric !== this.state.metricView || !this.state.metricView) {
          const newState = {
            ...this.state,
            metricView: metric,
          };
          this.setState(newState);
        }
      };

      editCancel = () => {
        this.props.editCancel(() => {
          this.getGoalData();
          const newState = {
            ...this.state,
            validationError: null,
            showError: false,
            recurringWarning: false,
          };
          this.setState(newState);
        });
      };

      saveGoal = () => {
        const { goalId, editTeamGroupId, onEdit, onSaveEdit } = this.props;

        if (onSaveEdit) {
          onSaveEdit();
        } else {
          const newState = {
            ...this.state,
            submitting: true,
          };
          this.setState(newState, () => {
            this.props.goalsStore
              .saveGoal(goalId, editTeamGroupId)
              .then(() => {
                newState.submitting = false;
                newState.recurringWarning = false;
                this.setState(newState, () => {
                  this.props.commonStore.triggerToast('success', { content: `Your goal has been updated` });
                  this.getGoalData();
                  if (onEdit) {
                    onEdit();
                  }
                });
              })
              .catch(() => {
                newState.submitting = false;
                this.setState(newState);
                this.props.commonStore.triggerToast('error', { content: 'Something went wrong. Unable to save this goal at this time.' });
              });
          });
        }
      };

      checkValidationAndSave = (isValid) => {
        if (!isValid) {
          const validationError = this.getValidationErrors();
          const newState = {
            ...this.state,
            validationError,
            showError: true,
          };
          this.setState(newState);
        } else if (this.state.recurringWarning) {
          this.props.commonStore.triggerConfirm({
            content: "Changes to this goal's recurrence will impact all future recurring goals. Are you sure you want to continue?",
            cancel: () => this.props.commonStore.resetConfirmState(),
            confirmBtn: 'Continue',
            continue: () => {
              this.saveGoal();
              this.props.commonStore.resetConfirmState();
            },
          });
        } else {
          this.saveGoal();
        }
      };

      setValue = (key, val) => {
        const { setGoalData, goalData } = this.props.goalsStore;
        // If this is a recurring goal and you are changing the interval, set warning message
        if (
          (key === 'due_interval' && goalData.data.due_type === 'recurring' && goalData.data.due_interval && this.state.savedGoalData.due_interval !== val) ||
          (key === 'due_type' && val === 'recurring' && this.state.savedGoalData.due_type === 'recurring')
        ) {
          const newState = {
            ...this.state,
            recurringWarning: true,
          };
          this.setState(newState);
        } else if (this.state.recurringWarning && (goalData.data.due_type !== 'recurring' || this.state.savedGoalData.due_interval === val)) {
          // If there's a recurringWarning check and see if it should be removed
          const newState = {
            ...this.state,
            recurringWarning: false,
          };
          this.setState(newState);
        }
        setGoalData('team', key, val, false);
      };

      getValidationErrors = () => {
        const validationMap = GoalsUtil.getGoalValidationMap('team', this.props.goalsStore.goalData.data);
        if (!validationMap) {
          return null;
        }
        const sectionKeys = Object.keys(validationMap);
        for (let i = 0; i < sectionKeys.length; i++) {
          const section = validationMap[sectionKeys[i]];
          if (!section.valid) {
            return section.error;
          }
        }
        return null;
      };

      showEditButton = (isComplete, isExpired) => {
        const { user, team } = this.props.userStore;
        const { goalData } = this.props.goalsStore;
        const { teamAdminView, editView } = this.props;
        return (
          // IF this is the team admin view, AND not completed, AND not already in edit view, AND you are an owner of the goal, OR you can manage the team
          teamAdminView &&
          !isComplete &&
          !isExpired &&
          !editView &&
          ((goalData.data && goalData.data.owner_ids && goalData.data.owner_ids.indexOf(user.id) > -1) || (team?.permissions && team?.permissions.canManageTeam))
        );
      };

      showActionButton = (hasStarted, isExpired, isComplete, teamAdminView, editView) => {
        const goalIsActive = hasStarted && !isExpired && !isComplete;
        return !teamAdminView && !editView && goalIsActive;
      };

      getActionText = (goal) => {
        return `${goal.progress && goal.progress.percent_completed ? 'Continue' : 'Start'} Learning`;
      };

      render() {
        const { goalData, goalParticipants, goalOwners, filterOptions } = this.props.goalsStore;
        const { team, user } = this.props.userStore;
        const { trees, setHierarchySelectOptions } = this.props.teamHierarchyStore;
        const teamHierarchy = team ? trees[team.id] : {};
        const {
          toggleEditGoal,
          progressTeamGroupId,
          goalId,
          teamAdminView,
          continueLearning,
          groupScopeQuery,
          teamId,
          editView,
          filterGroupsByPermissions,
          menuOptions,
          isExtendingGoal,
        } = this.props;

        if (goalData.loading) {
          return (
            <div>
              <Loading message="Loading..." />
            </div>
          );
        }
        if (goalData.error) {
          return (
            <div>
              <StyledError error={goalData.error} />
            </div>
          );
        }
        if (!goalData.data) {
          return null;
        }
        // Check if this goal can be managed by this user (either user is an owner of the goal, or admin of team)
        const goalType = goalData.data.team_id ? 'team' : 'user';
        const isBaseliningGoal = goalData.data.outcome_type.includes('baselining_');
        const isLearnerDecides = goalData.data.content_type === 'none';
        const hasPracticeTest = goalData.data.certification_test && goalData.data.certification_test.id;
        const sectionTitleClasses = 'text-gray-600 text-2xs font-semibold uppercase mb-2';
        const participantsHeading = teamAdminView ? 'Progress & Participants' : 'Progress';
        const participantType = GoalsUtil.getParticipantType(goalData.data, team);
        const participantsDisplay = GoalsUtil.getParticipantsDisplay(participantType, goalData.data.participants, team);
        const progressWrapperClasses = teamAdminView ? 'flex-1' : 'lg:mr-16';
        const hasStarted = !this.state.savedGoalData.start_date || moment.utc(this.state.savedGoalData.start_date).isBefore(moment.utc());
        const isExpired = goalData.data.due_date && moment.utc(goalData.data.due_date).isBefore(moment.utc());
        const isComplete = goalData.data.progress && goalData.data.progress.percent_completed && 1 * goalData.data.progress.percent_completed === 100;
        const showEditButton = this.showEditButton(isComplete, isExpired);
        const showActionButton = this.showActionButton(hasStarted, isExpired, isComplete, teamAdminView, editView);
        const isGoalValid = !this.state.submitting && GoalsUtil.checkGoalValid(teamAdminView ? 'team' : 'user', goalData.data, editView, !hasStarted);
        const canEditOwner = goalType !== 'user' && !isBaseliningGoal;
        const isDemo = goalData.data.id === 'demo';
        const buttonClassList = `py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm ${
          isDemo ? 'cursor-not-allowed' : ''
        }`;

        return (
          <>
            <div className="overflow-y-auto relative py-4 px-8 goal-details" style={editView ? { height: 'calc(100vh - 10.5rem)' } : {}}>
              {editView && (
                <>
                  <Title title={isExtendingGoal ? 'Edit Goal Due Date' : 'Edit Goal'} omitPadding wrapperClasses="mb-4" />
                  <p className="mb-9 text-sm text-gray-600">
                    {isExtendingGoal
                      ? 'Go ahead and extend your goal due date - note that some sections cannot be edited'
                      : 'Go ahead and edit your goal - note that some sections cannot be edited'}
                  </p>
                </>
              )}
              <div className={`${editView ? 'mb-8' : 'mb-12'} lg:flex justify-between items-start`}>
                <div className={`${editView ? 'w-full' : 'w-3/5'}`}>
                  <p className={sectionTitleClasses}>Goal Title</p>
                  {isExtendingGoal ? (
                    <span>{goalData.data.name}</span>
                  ) : (
                    <GoalTitle value={goalData.data.name} setValue={this.setValue} editView={!!editView} isBaseliningGoal={isBaseliningGoal} />
                  )}
                </div>
                <div className="mt-4">
                  {showEditButton && (
                    <button className={buttonClassList} onClick={() => toggleEditGoal(null, null, goalId)} disabled={isDemo}>
                      Edit
                    </button>
                  )}
                  {showActionButton ? (
                    <button className={buttonClassList} onClick={() => continueLearning(goalData.data)} disabled={isDemo}>
                      {this.getActionText(goalData.data)}
                    </button>
                  ) : null}
                </div>
              </div>

              <If condition={!isExtendingGoal}>
                {editView ? (
                  <GoalParticipants
                    displayValue={participantsDisplay}
                    setValue={this.setValue}
                    selectedParticipantType={participantType}
                    selectedParticipantIds={goalData.data.participant_ids}
                    members={{ ...goalParticipants }}
                    groups={{ ...teamHierarchy }}
                    team={team}
                    searchGroups={(query) => setHierarchySelectOptions(team?.id, filterGroupsByPermissions, query, null, true)}
                    filterOptions={filterOptions}
                    hidden={goalType === 'user'}
                    includeAdmins={goalData.data.include_admins}
                  />
                ) : (
                  <div className="mb-8 progress-container">
                    <div className="flex justify-between items-center mb-2">
                      <p className="mb-0 text-2xs font-semibold text-gray-600 uppercase">{participantsHeading}</p>
                      <ProgressMetricsSelector
                        changeMetric={this.changeMetricView}
                        selectedMetric={this.state.metricView}
                        outcomeSettings={goalData.data.outcome_settings}
                        hasPracticeTest={goalData.data.certification_test}
                        outcomeType={goalData.data.outcome_type}
                      />
                    </div>
                    <div className="xl:flex xl:justify-between">
                      <div className={progressWrapperClasses}>
                        <GoalProgress
                          teamAdminView={teamAdminView}
                          teamId={teamId}
                          teamGroupId={progressTeamGroupId}
                          goalData={goalData}
                          metricView={this.state.metricView}
                          goalsStore={this.props.goalsStore}
                          groupScopeQuery={groupScopeQuery}
                          hasPracticeTest={hasPracticeTest}
                          getPracticeTestStatus={GoalsUtil.getPracticeTestStatus}
                        />
                      </div>
                      <GoalInstructionalText
                        practiceTest={goalData.data.certification_test}
                        metric={this.state.metricView}
                        outcomeType={goalData.data.outcome_type}
                        isLearnerDecides={isLearnerDecides}
                        hidden={teamAdminView}
                      />
                    </div>
                  </div>
                )}
              </If>
              <div>
                <p className={sectionTitleClasses}>Desired Outcome</p>
                <p className="mb-8 text-sm">{GoalsUtil.getOutcomeDisplay(goalData.data.outcome_type, goalData.data.outcome_settings)}</p>
                <p className={sectionTitleClasses}>Learning Activity Type</p>
                {/* For now, contents will always be a single item in an array */}
                <div className="mb-8 text-sm">
                  {GoalsUtil.getLearningActivityDisplay({
                    orgId: team?.id,
                    contents: goalData.data.contents,
                    contentType: goalData.data.content_type,
                    omitContentType: true,
                    openInNewTab: !!editView,
                    isAdminMode: !!teamAdminView,
                  })}
                </div>
                {goalData.data.certification_test ? (
                  <>
                    <p className={sectionTitleClasses}>Practice Test</p>
                    <p className="mb-8 text-sm">{goalData.data.certification_test.title}</p>
                  </>
                ) : null}
                <If condition={!isExtendingGoal}>{goalType === 'team' && <GoalDescription value={goalData.data.description} setValue={this.setValue} editView={!!editView} />}</If>
                <p className={sectionTitleClasses}>Start Date</p>
                <GoalStartTimeFrame startDate={goalData.data.start_date} setValue={this.setValue} editView={!!editView} goalType={goalType} hasStarted={hasStarted} />
                <p className={sectionTitleClasses}>Due Date</p>
                <GoalTimeframe
                  dueType={goalData.data.due_type}
                  savedDueType={this.state.savedGoalData.due_type}
                  dueDate={goalData.data.due_date}
                  dueInterval={goalData.data.due_interval}
                  setValue={this.setValue}
                  editView={!!editView}
                  goalType={goalType}
                  goalOutcomeType={goalData.data.outcome_type}
                />
                <If condition={canEditOwner && !isExtendingGoal}>
                  <>
                    <p className={sectionTitleClasses}>Owner</p>
                    <GoalOwners
                      selectedOwners={goalData.data.owners}
                      selectedOwnerIds={goalData.data.owner_ids}
                      owners={{ ...goalOwners }}
                      editView={editView}
                      setValue={this.setValue}
                      userId={user.id}
                      filterOptions={filterOptions}
                    />
                  </>
                </If>
              </div>
            </div>
            {!!editView && (
              <GoalEditActions
                showError={this.state.showError}
                error={this.state.validationError}
                editCancel={this.editCancel}
                saveGoal={this.checkValidationAndSave}
                isValid={isGoalValid}
              />
            )}
            <div className="flex flex-row flex-wrap items-center px-5 mt-5 mb-10">
              {menuOptions?.map((option) => {
                let buttonClasses = 'text-black bg-gray-200';

                if (option.type === 'destructive') {
                  buttonClasses = 'bg-red-500 text-white';
                }

                return (
                  <button className={twMerge('py-2.5 px-6 text-sm font-bold leading-5 text-center rounded-sm mr-5', buttonClasses)} onClick={option.action} key={option.text}>
                    {option.text}
                  </button>
                );
              })}
            </div>
          </>
        );
      }
    }
  )
);

export default GoalDetails;
