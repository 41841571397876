import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select, { components } from 'react-select';
import FormatUtil, { getCN } from '../../utils/formatUtil';
import MultiSelectRemoveSelection from '../FormFields/MultiSelectRemoveSelection';
import MultiSelectRemoveAllSelections from '../FormFields/MultiSelectRemoveAllSelections';
import ReactSelectInput from '../FormFields/ReactSelectInput';
import ReactSelectMenuList from '../FormFields/ReactSelectMenuList';
import CheckboxDisplay from '../Mitre/CheckboxDisplay';
import StyleUtil from '../../utils/styleUtil';

function Dropdown(props) {
  const {
    name,
    options,
    ariaDescribedBy,
    ariaLabel,
    required,
    placeholder,
    disabled,
    error,
    onChange,
    id,
    value,
    loading,
    isMulti,
    isSearchable,
    isClearable,
    isCompact,
    isTransparent = true,
    onBlur,
    onMouseDown,
    classes,
    hideSelectedOptions = true,
    showOptionsCheckboxes,
    omitSelectedLabels,
    renderOptionComponent,
  } = props;
  const dropdownName = name || uuidv4();

  const formatOption = (option) => {
    if (option) {
      return { label: option.text, value: option.value, indented: option.indented, isDisabled: option.disabled };
    }
    return null;
  };

  const formatOptions = (selectOptions) => {
    if (selectOptions && selectOptions.length) {
      return selectOptions.map((option) => {
        return formatOption(option);
      });
    }
    return [];
  };

  const handleFocus = () => {
    const element = document.querySelector(`.auto-text-${dropdownName}`);
    if (element) {
      element.classList.add('border-blue-500');
    }
  };

  const handleBlur = () => {
    const element = document.querySelector(`.auto-text-${dropdownName}`);
    if (element) {
      element.classList.remove('border-blue-500');
    }
    if (onBlur) {
      onBlur();
    }
  };

  const selectClass = getCN(`
    auto-text-${dropdownName}
    border rounded-sm pl-1
    ${error ? 'border-red-500 bg-red-100 placeholder-gray-600' : 'border-gray-400 placeholder-gray-600'}
    ${disabled && 'bg-gray-300 cursor-not-allowed'} ${classes || ''}
  `);

  const getSelectedVal = (opts, val, isMultiSelect) => {
    if (!val || !opts || !opts.length) {
      return '';
    }
    if (isMultiSelect) {
      return opts.map((opt) => {
        if (val.indexOf(opt.value) > -1) {
          return formatOption(opt);
        }
        return null;
      });
    }
    for (let i = 0; i < opts.length; i++) {
      const opt = opts[i];
      if (val === opt.value) {
        return formatOption(opt);
      }
    }
    return null;
  };

  const handleOnChange = (selectValue, action) => {
    onChange(selectValue, action);
  };

  const OptionComponent = useCallback(
    (optionProps) => {
      const newProps = { ...optionProps };
      if (newProps.onMouseDown) {
        newProps.innerProps.onMouseDown = () => {
          newProps.onMouseDown(newProps, 'addOption');
        };
      }

      if (renderOptionComponent) {
        return (
          <components.Option {...newProps} className="!px-0">
            {renderOptionComponent(newProps)}
          </components.Option>
        );
      }

      return (
        <components.Option {...newProps}>
          {newProps.showOptionsCheckboxes && <CheckboxDisplay className="mt-1 mr-2" isSelected={newProps.isSelected} />}
          {newProps.label}
        </components.Option>
      );
    },
    [renderOptionComponent]
  );

  const selectedValue = getSelectedVal(options, value, isMulti);
  const optionComp = useCallback(
    (optionProps) => <OptionComponent {...optionProps} onMouseDown={onMouseDown} showOptionsCheckboxes={showOptionsCheckboxes} />,
    [onMouseDown, showOptionsCheckboxes]
  );
  const multiValueRemove = (removeProps) => <MultiSelectRemoveSelection {...removeProps} onMouseDown={onMouseDown} />;
  const menuId = FormatUtil.lowerCaseHyphenText(`menu-${dropdownName}`);
  const ariaLiveMessages = {
    onFocus: (option) => {
      const isSelected = option && option.focused && selectedValue && selectedValue.value && option.focused.value === selectedValue.value;
      return `Option ${option.label}.${isSelected ? ' Selected.' : ''}`;
    },
    guidance: () => '',
    onFilter: () => '',
  };

  return (
    <>
      <p id={`${dropdownName}-instructions`} className="sr-only">
        Type to refine list, or use arrows to cycle through list options
      </p>
      <Select
        name={dropdownName}
        value={selectedValue}
        options={formatOptions(options)}
        isMulti={!!isMulti}
        isSearchable={!!isSearchable}
        isClearable={!!isClearable}
        isLoading={!!loading}
        onChange={handleOnChange}
        placeholder={placeholder}
        styles={StyleUtil.getReactSelectCustomStyles(showOptionsCheckboxes, isCompact, isTransparent)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={selectClass}
        closeMenuOnSelect={!isMulti}
        defaultMenuIsOpen={false}
        inputId={id}
        hideSelectedOptions={hideSelectedOptions}
        aria-label={ariaLabel}
        aria-required={required}
        aria-describedby={`${ariaDescribedBy || ''} ${dropdownName}-instructions`}
        ariaLiveMessages={ariaLiveMessages}
        isDisabled={disabled}
        controlShouldRenderValue={!omitSelectedLabels}
        components={{
          MultiValueRemove: multiValueRemove,
          ClearIndicator: MultiSelectRemoveAllSelections,
          Input: ReactSelectInput,
          Option: optionComp,
          MenuList: ReactSelectMenuList,
        }}
        menuId={menuId}
      />
    </>
  );
}

export default Dropdown;
